import { Fragment, useState } from "react";

import Button from "components/atoms/Button";

import SelectSyncType from "./SelectSyncType";
import EditCalendarsSelectedDialog from "../Calendar/EditCalendarsSelectedDialog";

const SynchronizeCalendarsModal = () => {
  const [openSynchronizeCalendarsModal, setOpenSynchronizeCalendarsModal] =
    useState(false);

  const [openEditCalendarsSelected, setOpenEditCalendarsSelected] =
    useState(false);

  const toggleOpenSynchronizeCalendarsModal = () =>
    setOpenSynchronizeCalendarsModal(!openSynchronizeCalendarsModal);

  const toggleOpenEditCalendarsSelected = () =>
    setOpenEditCalendarsSelected(!openEditCalendarsSelected);

  return (
    <Fragment>
      <div>
        <Button
          handleClick={toggleOpenSynchronizeCalendarsModal}
          text="Synchronize Calendars"
        />
        {openSynchronizeCalendarsModal && (
          <SelectSyncType
            onClose={toggleOpenSynchronizeCalendarsModal}
            onSuccess={toggleOpenEditCalendarsSelected}
          />
        )}
        {openEditCalendarsSelected && (
          <EditCalendarsSelectedDialog
            handleClose={toggleOpenEditCalendarsSelected}
          />
        )}
      </div>
    </Fragment>
  );
};

export default SynchronizeCalendarsModal;
