import styled from "styled-components";

export const StyledTrackerSection = styled.div<{
  fullwidth?: boolean;
  largeContainer?: boolean;
  isMobile: boolean;
}>`
  border-left: ${(props) => !props.fullwidth && "1px solid #eef5fc14"};
  display: ${(props) => props.fullwidth && "flex"};
  align-items: ${(props) => props.fullwidth && "center"};
  flex-direction: ${(props) => props.fullwidth && "column"};
  padding-bottom: ${(props) => props.isMobile && "70px"};
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: red;
`;
