import React from "react";

import { StyledDialogContainer } from "./styles";

type Props = {
  children: React.ReactNode;
};

const DialogContainer = (props: Props) => {
  return <StyledDialogContainer>{props.children}</StyledDialogContainer>;
};

export default DialogContainer;
