export const getDaysInMonth = (
  month: number,
  year: number,
): { dayNumber: number; dayName: string }[] => {
  const daysInMonth = new Date(year, month, 0).getDate();

  const daysInMonthArray = [];
  for (let i = 1; i <= daysInMonth; i++) {
    daysInMonthArray.push({
      dayNumber: i,
      dayName: new Date(year, month - 1, i)
        .toLocaleString("default", {
          weekday: "long",
        })
        .slice(0, 1)
        .toUpperCase(),
    });
  }

  return daysInMonthArray;
};

export const dateToStoringFormat = (date: Date): string => {
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const storingFormatToDate = (date: string): Date => {
  const [year, month, day] = date.split("-");
  const dateFormatted = new Date(
    Number(year),
    Number(month) - 1,
    Number(day) + 1,
  );
  dateFormatted.setDate(dateFormatted.getDate());
  return dateFormatted;
};

export interface DayInterface {
  dayNumber: number;
  name: string;
  dayMonth: string;
  isActive: boolean;
  id: string;
  monthKey: string;
}

export const getCurrentWeekDays = (
  firstDay?: Date,
  firstDayBis?: Date,
): DayInterface[] => {
  const curr = firstDay ? new Date(firstDay) : new Date();
  const today = firstDayBis ? new Date(firstDayBis) : new Date();
  const first = new Date(
    today.setDate(
      today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1),
    ),
  );

  const isMobile = window.innerWidth < 768;

  const days = [];
  for (let i = 0; i < 7; i++) {
    const next = new Date(first);
    next.setDate(first.getDate() + i);
    const month = next.getMonth();
    const year = next.getFullYear();
    const monthKey = `${year}-${String(month + 1).padStart(2, "0")}`;
    let dayName = next.toLocaleString("default", { weekday: "short" });
    dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);

    days.push({
      dayNumber: next.getDate(),
      name: `${dayName} ${
        !isMobile
          ? new Date(0, month).toLocaleString("default", { month: "long" })
          : ""
      } ${next.getDate()} `,
      dayMonth: next.toLocaleString("default", { month: "short" }),
      isActive: next.getDate() === curr.getDate(),
      id: dateToStoringFormat(next),
      monthKey: monthKey,
    });
  }

  return days;
};

export interface DateRangeStateInterface {
  name: string;
  isActive: boolean;
  isActiveWithDelay: boolean;
  id: "day" | "week" | "month" | "quarter" | "year";
}

export const periodRange = (pathname: string): DateRangeStateInterface[] => {
  return [
    {
      name: "Day",
      isActive: pathname.includes("day") || pathname === "/",
      isActiveWithDelay: pathname.includes("day") || pathname === "/",
      id: "day",
    },
    {
      name: "Week",
      isActive: pathname.includes("week"),
      isActiveWithDelay: pathname.includes("week"),
      id: "week",
    },
    {
      name: "Month",
      isActive: pathname.includes("month"),
      isActiveWithDelay: pathname.includes("month"),
      id: "month",
    },
    {
      name: "Quarter",
      isActive: pathname.includes("quarter"),
      isActiveWithDelay: pathname.includes("quarter"),
      id: "quarter",
    },
    {
      name: "Year",
      isActive: pathname.includes("year"),
      isActiveWithDelay: pathname.includes("year"),
      id: "year",
    },
  ];
};

export interface WeekInterface {
  name: string;
  isActive: boolean;
  id: string;
  firstDay: Date;
  month: number;
  monthName: string;
  year: number;
  start: Date;
  end: Date;
}

export const getSixWeeks = (customFirstDay?: Date): WeekInterface[] => {
  const today = customFirstDay || new Date();

  today.setDate(today.getDate() - today.getDay() - 14);
  const weeks = [];
  for (let i = 0; i < 5; i++) {
    const firstDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 1),
    );
    const month = firstDay.getMonth();
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 7),
    );

    weeks.push({
      id: `${dateToStoringFormat(firstDay)}!${dateToStoringFormat(lastDay)}`,
      name: `${firstDay.getDate()}-${lastDay.getDate()} ${lastDay.toLocaleString(
        "en",
        { month: "long" },
      )}`,
      monthName: `${firstDay.getFullYear()}-${(month + 1)
        .toString()
        .padStart(2, "0")}`,
      start: firstDay,
      end: lastDay,
      month: month + 1,
      year: firstDay.getFullYear(),
      isActive: customFirstDay
        ? i === 0
        : new Date().getTime() >= firstDay.getTime() &&
          new Date().getTime() <= lastDay.getTime(),
      firstDay: firstDay,
    });
  }
  getQuarters();
  return weeks;
};

export interface MonthInterface {
  id: string;
  name: string;
  isActive: boolean;
  referenceDay: Date;
  number: number;
  start?: Date;
}

export const getMonths = (referenceDate?: Date): MonthInterface[] => {
  const currentDate = referenceDate || new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const months = [];
  for (let i = -2; i <= 2; i++) {
    // Calculate the number of months to add to the current month to get the target month for the current iteration
    const targetMonth = currentMonth + i;

    // Calculate the target year for the current iteration based on the number of months to add
    let targetYear = currentYear;
    if (targetMonth < 0) {
      targetYear = targetYear + Math.floor(targetMonth / 12);
    } else if (targetMonth > 11) {
      targetYear = targetYear + Math.ceil(targetMonth / 12);
    }

    // Calculate the target month for the current iteration
    const targetMonthOfYear = ((targetMonth % 12) + 12) % 12;

    // Format the id and name for the current iteration
    const formattedId = `${targetYear}-${(targetMonthOfYear + 1)
      .toString()
      .padStart(2, "0")}`;
    const formattedName = `${new Date(
      targetYear,
      targetMonthOfYear,
      1,
    ).toLocaleString("en", {
      month: "long",
    })} ${targetYear}`;

    months.push({
      id: formattedId,
      name: formattedName,
      number: targetMonth + 1,
      referenceDay: currentDate,
      isActive: i === 0,
    });
  }

  return months;
};

export interface QuarterInterface {
  name: string;
  isActive: boolean;
  id: string;
  referenceYear: number;
  referenceMonth: string;
}

export const getQuarters = (customYear?: number): QuarterInterface[] => {
  const month = new Date().getMonth();
  const currentQuarter = month < 3 ? 1 : month < 6 ? 2 : month < 9 ? 3 : 4;
  const year = customYear || new Date().getFullYear();

  const quarters = [];
  for (let i = 0; i < 4; i++) {
    quarters.push({
      id: `${year}-${i + 1}`,
      name: `Quarter ${i + 1} - ${year}`,
      isActive: i === currentQuarter - 1,
      referenceMonth: ` ${year}-${(i + 1) * 3}`,
      referenceYear: year,
    });
  }

  return quarters;
};

export interface YearInterface {
  name: string;
  isActive: boolean;
  id: string;
}

export const getYears = (): YearInterface[] => {
  const year = new Date().getFullYear();
  const years = [];
  for (let i = -3; i < 3; i++) {
    years.push({
      id: `${year + i}`,
      name: `${year + i}`,
      isActive: i === 0,
    });
  }

  return years;
};

export const getActivePeriod = (
  periodArray:
    | DayInterface[]
    | WeekInterface[]
    | MonthInterface[]
    | QuarterInterface[]
    | YearInterface[],
): string => {
  return periodArray.find((item) => item.isActive)?.id || periodArray[0].id;
};

export type SingularPeriods = "day" | "week" | "month" | "quarter" | "year";

export type PluralPeriods = "days" | "weeks" | "months" | "quarters" | "years";

export const getSingularPeriod = (period: PluralPeriods): SingularPeriods => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return period.substring(0, period.length - 1);
};

export const getPluralPeriod = (period: SingularPeriods): PluralPeriods => {
  return `${period}s`;
};

export const getPreviousMonthYyyyMmFormat = (month: string) => {
  const [year, currentMonth] = month.split("-").map(Number);
  const currentDate = new Date(year, currentMonth - 1, 1);
  const previousMonth = new Date(currentDate.getTime() - 1);
  return `${previousMonth.getFullYear()}-${(previousMonth.getMonth() + 1)
    .toString()
    .padStart(2, "0")}`;
};

export const getNextDayYyyyMmDdFormat = (day: string) => {
  const [year, month, currentDay] = day.split("-").map(Number);
  const currentDate = new Date(year, month - 1, currentDay);
  const nextDay = new Date(currentDate.getTime() + 86400000);
  return `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${nextDay.getDate().toString().padStart(2, "0")}`;
};

export const getInitialPeriodId = (pathname: string): PluralPeriods => {
  if (pathname.includes("/days")) {
    return "days";
  } else if (pathname.includes("/weeks")) {
    return "weeks";
  } else if (pathname.includes("/months")) {
    return "months";
  } else if (pathname.includes("/quarters")) {
    return "quarters";
  } else if (pathname.includes("/years")) {
    return "years";
  } else {
    return "days";
  }
};

export const getNextSevenDaysNumber = (startDate: Date) => {
  const dayNumbers = [];
  const dayNames = [];
  const monthKeys = [];

  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
    dayNumbers.push(nextDay.getDate());
    dayNames.push(
      nextDay
        .toLocaleString("default", {
          weekday: "long",
        })
        .slice(0, 1)
        .toUpperCase(),
    );
    monthKeys.push(
      `${nextDay.getFullYear()}-${(nextDay.getMonth() + 1)
        .toString()
        .padStart(2, "0")}`,
    );
  }
  return { dayNames, dayNumbers, monthKeys };
};

export const lastThreeMonths = (date: string) => {
  const result = [];
  const [year, month] = date.split("-").map(Number);
  for (let i = 2; i >= 0; i--) {
    const date = new Date(year, month - 1 - i, 1);
    result.push(
      date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, "0"),
    );
  }
  return result;
};

export const getFirstDayOfTheWeek = (date: Date) => {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};
