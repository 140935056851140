import { useState, useContext } from "react";

import Tag from "components/molecules/Tag";
import { TrackersContext, TrackInterface } from "contexts/TrackersContext";

type TrackerProps = {
  tracker: TrackInterface;
};

const TrackerSettingsItem = ({ tracker }: TrackerProps) => {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { deleteTracker } = useContext(TrackersContext);

  const handleDeleteTracker = async () => {
    setLoadingDelete(true);
    await deleteTracker(tracker.id);
    setLoadingDelete(false);
  };

  return (
    <Tag
      loading={loadingDelete}
      text={tracker.name}
      handleDelete={handleDeleteTracker}
    />
  );
};

export default TrackerSettingsItem;
