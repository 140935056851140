import { StyledButton } from "./style";

interface ButtonProps {
  handleClick: () => void | any;
  text: string;
  className?: string;
}

const Button = ({ handleClick, text, className = "" }: ButtonProps) => (
  <StyledButton className={className} onClick={() => handleClick()}>
    {text}
  </StyledButton>
);

export default Button;
