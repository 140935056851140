import { DefaultTheme, ThemeProvider } from "styled-components";

export const theme: DefaultTheme = {
  main: {
    colors: {
      primary: "#2c3e50",
      primaryLight: "#445c74",
      primaryXLight: "#9fb3c8",
      bgTasks: "#ffffff",
      bgTasksTodo: "#f0f8ff",
      bgTasksDone: "#f6fff0",
      secondary: "#ffffff",
    },
    breakpoints: {
      xs: "0px",
      sm: "576px",
      md: "800px",
      lg: "1080px",
      xl: "1500px",
    },
  },
};

interface ThemeProps {
  children: React.ReactNode;
}

const Theme = (props: ThemeProps): React.ReactElement => (
  <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
);

export default Theme;
