import { useContext } from "react";

import TitleSection from "components/atoms/TitleSection";
import MonthTrackers from "components/organism/Trackers/MonthTrackers";
import WeeksTrackers from "components/organism/Trackers/WeeksTrackers";
import { TimeContext } from "contexts/TimeContext";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "./styles";
import { TrackerModeType } from "..";
import { useIsMobile } from "../../../../customHooks/index";

interface TrackersInterface {
  currentTrackerMode: TrackerModeType;
  toggleOpenTrackersSettings?: () => void;
}

const Trackers = ({
  currentTrackerMode,
  toggleOpenTrackersSettings,
}: TrackersInterface) => {
  const { t } = useTranslation();

  const { currentPeriodId } = useContext(TimeContext);

  const isMobile = useIsMobile();

  return (
    <StyledContainer isMobile={isMobile}>
      <TitleSection>{t("trackers")}</TitleSection>

      {currentPeriodId === "weeks" && (
        <WeeksTrackers
          currentTrackerMode={currentTrackerMode}
          toggleOpenTrackersSettings={toggleOpenTrackersSettings}
        />
      )}
      {currentPeriodId === "months" && (
        <MonthTrackers
          currentTrackerMode={currentTrackerMode}
          toggleOpenTrackersSettings={toggleOpenTrackersSettings}
        />
      )}
    </StyledContainer>
  );
};

export default Trackers;
