import CircularProgress from "@mui/material/CircularProgress";

import { StyledLoaderListContainer } from "./styles";

const LoaderList = () => {
  return (
    <StyledLoaderListContainer>
      <CircularProgress color="inherit" />
    </StyledLoaderListContainer>
  );
};

export default LoaderList;
