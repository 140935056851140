import { Fragment, useContext } from "react";

import DateRangeSelectorMonths from "components/molecules/DateRangeSelectorMonth";
import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import Calendar from "components/organism/Calendar/ViewWeekMonth";
import TasksContainer from "components/organism/TasksContainer";
import {
  TABLE_MODE,
  TrackerModeType,
} from "components/template/CalendarAndTrackers";
import Trackers from "components/template/CalendarAndTrackers/Trackers";
import DashboardTemplate from "components/template/Dashboard";
import { LayoutContext } from "contexts/LayoutContext";
import { TimeContext } from "contexts/TimeContext";
import { useIsMobile } from "customHooks";

import { StyledContainer } from "./styles";

const DashboardWeek = () => {
  const { dateRanges } = useContext(TimeContext);
  const { periodRange } = dateRanges;

  const { mobileSectionToDisplay } = useContext(LayoutContext);

  const currentTrackerMode: TrackerModeType = TABLE_MODE;

  const isMobile = useIsMobile();
  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <DateRangeSelectorPeriodType />
          <DateRangeSelectorMonths />
          <DashboardTemplate>
            <StyledContainer>
              {mobileSectionToDisplay === "tasks" && (
                <TasksContainer
                  taskType="months"
                  visible={periodRange[2].isActiveWithDelay}
                />
              )}
              {mobileSectionToDisplay === "calendar" && <Calendar />}
              {mobileSectionToDisplay === "trackers" && (
                <Trackers currentTrackerMode={currentTrackerMode} />
              )}
            </StyledContainer>
          </DashboardTemplate>
        </Fragment>
      ) : (
        <Fragment>
          <DateRangeSelectorPeriodType />
          <DateRangeSelectorMonths />
          <DashboardTemplate>
            <TasksContainer
              taskType="months"
              visible={periodRange[2].isActiveWithDelay}
            />
            <Calendar />
            <Trackers currentTrackerMode={currentTrackerMode} />
          </DashboardTemplate>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DashboardWeek;
