import { useLayoutEffect, useState } from "react";

export const useIsMobile = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  const handleSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    handleSize();

    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, []);

  return windowWidth < 640;
};
