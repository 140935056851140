import { useContext } from "react";

import { UserContext } from "contexts/UserContext";
import { Navigate, RouteProps } from "react-router-dom";
import { DAYS_DASHBOARD_ROUTE } from "routes";

const UnAuthenticatedRoute = ({ children }: RouteProps): JSX.Element => {
  const { currentUser } = useContext(UserContext);

  return <>{currentUser ? <Navigate to={DAYS_DASHBOARD_ROUTE} /> : children}</>;
};

export default UnAuthenticatedRoute;
