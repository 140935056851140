import { useContext, useState } from "react";

import LoaderList from "components/atoms/LoaderList";
import NewBacklogTask from "components/atoms/NewBacklogTask";
import NewTask from "components/atoms/NewTask";
import TitleSection from "components/atoms/TitleSection";
import FadeIn from "components/molecules/FadeIn";
import TasksList from "components/molecules/TasksList";
import {
  StyledTasksContainer,
  StyledCategoryTaskContainer,
} from "components/molecules/TasksList/styles";
import EditTaskDialog from "components/organism/Todos/EditTaskDialog";
import { TasksContext, TaskInterface } from "contexts/TasksContext";
import { useIsMobile } from "customHooks";
import { PluralPeriods } from "helpers/date";
import { useTranslation } from "react-i18next";

interface TasksContainerProps {
  taskType: PluralPeriods;
  visible?: boolean;
}

const TasksContainer = ({ taskType, visible }: TasksContainerProps) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const { tasksLoading, addTask, editTask, addTaskToBacklog, backlogTasks } =
    useContext(TasksContext);

  const [taskToEdit, setTaskToEdit] = useState<false | TaskInterface>(false);

  const openEditaskDialog = (task: TaskInterface) => {
    setTaskToEdit(task);
  };

  const handleCloseOpenTaskToEdit = () => {
    setTaskToEdit(false);
  };

  return (
    <div>
      <StyledTasksContainer isMobile={isMobile}>
        <TitleSection>{t("tasks_list")}</TitleSection>
        {tasksLoading ? (
          <LoaderList />
        ) : (
          <FadeIn visible={visible}>
            <StyledCategoryTaskContainer withMargin>
              <NewBacklogTask addTask={addTaskToBacklog} />
              <NewTask addTask={addTask} backlogTasks={backlogTasks} />
            </StyledCategoryTaskContainer>
            <TasksList
              taskType={taskType}
              openEditaskDialog={openEditaskDialog}
            />
            {taskToEdit && (
              <EditTaskDialog
                initialTaskToEdit={taskToEdit}
                editTask={editTask}
                handleClose={handleCloseOpenTaskToEdit}
              />
            )}
          </FadeIn>
        )}
      </StyledTasksContainer>
    </div>
  );
};

export default TasksContainer;
