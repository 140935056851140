import { useEffect, useState, createContext } from "react";

import { onAuthStateChanged, User } from "firebase/auth";

import { auth } from "../fire/init";
import { deleteUser as _deleteUser } from "../fire/users";

interface UserContextInterface {
  children: React.ReactNode;
}

export const UserContext = createContext<any>(null);

const UserProvider = ({ children }: UserContextInterface) => {
  const [currentUser, setCurrentUser] = useState<null | User | false>(null);

  const deleteUser = async () => {
    try {
      if (!currentUser) return;
      await _deleteUser(currentUser.uid);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(false);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, deleteUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
