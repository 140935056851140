import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // New import
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA5lWEGjwr2Ntbrp4TqDKm_5q4FuTrQJD8",
  authDomain: "woaz-5499d.firebaseapp.com",
  projectId: "woaz-5499d",
  storageBucket: "woaz-5499d.appspot.com",
  messagingSenderId: "641665729796",
  appId: "1:641665729796:web:87904ceb593077254f7c51",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
