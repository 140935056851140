import { useContext } from "react";

import FadeIn from "components/molecules/FadeIn";
import { TimeContext } from "contexts/TimeContext";
import { TrackersContext, TrackInterface } from "contexts/TrackersContext";

import { StyledTrackerDay } from "./styles";

const DaysTable = () => {
  const { trackers, toggleCompleteTrack } = useContext(TrackersContext);
  const { dateRanges } = useContext(TimeContext);

  const currentDay =
    dateRanges.days.find((day) => day.isActive)?.dayNumber ||
    dateRanges.days[0].dayNumber;

  const handleToggleCompleteTrack = (
    trackerId: string,
    tracker: TrackInterface,
  ) => {
    toggleCompleteTrack(trackerId, currentDay, tracker);
  };

  return (
    <FadeIn>
      {trackers
        .filter((tracker) => tracker.completedDays.includes(currentDay))
        .map((tracker) => (
          <StyledTrackerDay
            key={tracker.id}
            isTicked={tracker.completedDays.includes(currentDay)}
            onClick={() => handleToggleCompleteTrack(tracker.id, tracker)}
          >
            {tracker.name}
          </StyledTrackerDay>
        ))}
      <br />
      {trackers
        .filter((tracker) => !tracker.completedDays.includes(currentDay))
        .map((tracker) => (
          <StyledTrackerDay
            key={tracker.id}
            isTicked={tracker.completedDays.includes(currentDay)}
            onClick={() => handleToggleCompleteTrack(tracker.id, tracker)}
          >
            {tracker.name}
          </StyledTrackerDay>
        ))}
    </FadeIn>
  );
};

export default DaysTable;
