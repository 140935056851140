import { Fragment, useContext } from "react";

import { TimeContext } from "contexts/TimeContext";
import { UserContext } from "contexts/UserContext";
import { auth } from "fire/init";
import { signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DAYS_DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  PROFILE_ROUTE,
  WEEK_TRACKERS_STATS,
} from "routes";

import {
  StyledLogo,
  StyledSignOut,
  StyledButtonsContainer,
  StyledTopBarContainer,
  StyledNavItem,
} from "./styles";

const TopBar = () => {
  const { currentUser } = useContext(UserContext);
  const { switchToWeekPeriod } = useContext(TimeContext);

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLogOut = () => {
    signOut(auth)
      .then((r) => console.log(r))
      .catch();
  };

  return (
    <StyledTopBarContainer>
      <StyledLogo onClick={() => navigate(DAYS_DASHBOARD_ROUTE)}>
        Bullet Flow
      </StyledLogo>
      <StyledButtonsContainer>
        {currentUser ? (
          <Fragment>
            <StyledNavItem
              isActive={window.location.pathname.includes("dashboard")}
              onClick={() => {
                if (pathname.includes("trackers")) {
                  const periodRange = pathname.split("/")[2];
                  navigate(`dashboard/${periodRange}`);
                } else {
                  navigate(DAYS_DASHBOARD_ROUTE);
                }
              }}
            >
              {t("dashboard")}
            </StyledNavItem>
            <StyledNavItem
              isActive={window.location.pathname.includes("trackers")}
              onClick={() => {
                if (pathname.includes("dashboard")) {
                  if (pathname.includes("day")) {
                    switchToWeekPeriod();
                    navigate(WEEK_TRACKERS_STATS);
                  } else {
                    const periodRange = pathname.split("/")[2];
                    navigate(`trackers/${periodRange}`);
                  }
                } else {
                  navigate(WEEK_TRACKERS_STATS);
                }
              }}
            >
              {t("trackers_stats")}
            </StyledNavItem>
            <StyledNavItem
              isActive={window.location.pathname.includes("profile")}
              onClick={() => navigate(PROFILE_ROUTE)}
            >
              {t("profile")}
            </StyledNavItem>
            <StyledSignOut onClick={handleLogOut}>Log out</StyledSignOut>
          </Fragment>
        ) : (
          <Fragment>
            <StyledNavItem
              isActive={window.location.pathname === "/"}
              onClick={() => navigate("/")}
            >
              {t("home")}
            </StyledNavItem>
            <StyledSignOut onClick={() => navigate(LOGIN_ROUTE)}>
              {t("login")}
            </StyledSignOut>
          </Fragment>
        )}
      </StyledButtonsContainer>
    </StyledTopBarContainer>
  );
};

export default TopBar;
