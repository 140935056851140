import { Fragment } from "react";

import styled from "styled-components";

const NotFoundPage = () => {
  const redirect: any = "/";
  return (
    <Fragment>
      <Container>
        <Title>404 - Page non trouvée</Title>
        <Description>
          Désolé, la page que vous recherchez n'a pas été trouvée. Veuillez
          vérifier l'URL ou revenir à la page d'accueil.
        </Description>
        <Button onClick={() => (window.location = redirect)}>
          Retourner à la page d'accueil
        </Button>
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background-color: #2ecc71;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #27ae60;
  }
`;

export default NotFoundPage;
