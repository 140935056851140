import { useContext } from "react";

import { TrackersContext } from "contexts/TrackersContext";

import { StyledTrackerName, StyledTrackerNamesContainer } from "./styles";

const TrackerColumns = () => {
  const { trackers } = useContext(TrackersContext);

  return (
    <StyledTrackerNamesContainer>
      {trackers.map((tracker, i) => (
        <StyledTrackerName key={tracker.id} isLast={i === trackers.length - 1}>
          {tracker.name}
        </StyledTrackerName>
      ))}
    </StyledTrackerNamesContainer>
  );
};

export default TrackerColumns;
