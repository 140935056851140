import { useNavigate } from "react-router-dom";
import { PRIVACY_ROUTE } from "routes";
import styled from "styled-components";

const Container = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
`;

const Text = styled.p`
  font-size: 16px;
  color: #666;
  margin: 0;
`;

const TextBis = styled.p`
  cursor: pointer;
  font-size: 16px;
  color: #666;
  margin: 0;
  padding-top: 8px;
`;

const StyledLogo = styled.div`
  font-size: 20px;
  letter-spacing: 4px;
  color: black;
  margin-bottom: 8px;
`;

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div>
        <StyledLogo>Bullet Flow</StyledLogo>
        <Text>Tous droits réservés © 2023</Text>
      </div>
      <div>
        <Text>Contactez-nous : cesar.bonte@gmail.com</Text>
        <TextBis onClick={() => navigate(PRIVACY_ROUTE)}>
          Conditions générales et confidentialité
        </TextBis>
      </div>
    </Container>
  );
};

export default Footer;
