import { useContext, useState } from "react";

import { useGoogleLogin } from "@react-oauth/google";
import GoogleCalendarButton from "components/atoms/GoogleCalendarButton/index";
import Loader from "components/atoms/Loader";
import Dialog from "components/molecules/Dialog";
import DialogContainer from "components/molecules/DialogContainer";
import { CalendarContext } from "contexts/CalendarContext";

interface EditCalendarsSelectedDialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const EditCalendarsSelectedDialog = ({
  onClose,
  onSuccess,
}: EditCalendarsSelectedDialogProps) => {
  const { initGoogleCalendar } = useContext(CalendarContext);

  const [isLoading, setIsLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (loginResponse) => {
      setIsLoading(true);
      const successInit = await initGoogleCalendar(loginResponse.code);
      if (successInit) {
        onClose();
        onSuccess();
      } else {
        console.log("error");
      }
      setIsLoading(false);
    },
    onError: (errorResponse) => {
      console.log(errorResponse);
      setIsLoading(false);
    },
    scope: "https://www.googleapis.com/auth/calendar.readonly",
  });
  return (
    <Dialog onClose={onClose} title="Calendars Synchronization">
      <DialogContainer>
        {isLoading ? (
          <Loader />
        ) : (
          <GoogleCalendarButton
            text="Synchronize Google Calendar"
            handleClick={googleLogin}
          />
        )}
      </DialogContainer>
    </Dialog>
  );
};

export default EditCalendarsSelectedDialog;
