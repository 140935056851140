import { useContext, Fragment } from "react";

import CalendarEvent from "components/molecules/CalendarEvent";
import { CalendarContext } from "contexts/CalendarContext";
import { useIsMobile } from "customHooks";

import {
  StyledDayTitle,
  StyledDayContainer,
  StyledDaysContainer,
} from "./styles";

const CalendarWeekEvents = () => {
  const { monthlyEvents } = useContext(CalendarContext);

  const isMobile = useIsMobile();

  return (
    <StyledDaysContainer isMobile={isMobile}>
      {monthlyEvents
        .filter((day: any) => day.events.length > 0)
        .map((day: any) => (
          <StyledDayContainer key={day.id}>
            <StyledDayTitle>{day.name}</StyledDayTitle>
            <Fragment>
              {day.events.map((event: any, i: number) => (
                <CalendarEvent key={i} event={event} noBorder />
              ))}
            </Fragment>
          </StyledDayContainer>
        ))}
    </StyledDaysContainer>
  );
};

export default CalendarWeekEvents;
