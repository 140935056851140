import AppsIcon from "@mui/icons-material/Apps";
import BarChartIcon from "@mui/icons-material/BarChart";
import InsightsIcon from "@mui/icons-material/Insights";

import { StyledStatSwitcherContainer, StyledTab } from "./styles";

type StatSwitcherProps = {
  trackerOptions: any[];
  onChangeStatOption: (value: any) => void;
};

const index = ({ trackerOptions, onChangeStatOption }: StatSwitcherProps) => {
  return (
    <StyledStatSwitcherContainer>
      {trackerOptions.map((trackerOption: any) => (
        <StyledTab
          isActive={trackerOption.isActive}
          key={trackerOption.label}
          onClick={() => onChangeStatOption(trackerOption.value)}
        >
          <div>
            {trackerOption.value === "graph" && <InsightsIcon />}
            {trackerOption.value === "table" && <AppsIcon />}
            {trackerOption.value === "barchart" && <BarChartIcon />}
          </div>
          <div>{trackerOption.label}</div>
        </StyledTab>
      ))}
    </StyledStatSwitcherContainer>
  );
};

export default index;
