import { Fragment } from "react";

import { StyledContainer } from "./styles";

const Privacy = () => {
  return (
    <Fragment>
      <StyledContainer>
        <h1>BulletFlow Privacy Policy</h1>
        <p>
          At BulletFlow, we take your privacy very seriously. This privacy
          policy explains how we collect, use, and protect your personal
          information when you use our bullet journal application connected to
          Google Calendar.
          <br />
          <h3>What Information We Collect</h3>
          <br />
          - Personal information: This includes your name, email address, and
          other contact information you provide us when you create an account
          with us.
          <br />
          - Journal entries: This includes the text and other media you input
          into your journal entries.
          <br />- Calendar data: This includes the events and other calendar
          data you sync with our application through your Google Calendar
          account.
          <br />
          <br />
          BulletFlow use and transfer to any other app of information received
          from Google APIs will adhere to{" "}
          <a href="https://developers.google.com/terms/api-services-user-data-policy">
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements
          <br />
          <br />
          We will not sell or rent your personal information to third parties.
          <br />
          <h3>How We Protect Your Information</h3>
          We take appropriate security measures to protect your personal
          information from unauthorized access, alteration, disclosure, or
          destruction. We use secure servers and data encryption to protect your
          information.
          <br />
          <br />
          <h3>Your Rights</h3>
          You have the right to:
          <br />
          Access, correct, or delete your personal information. <br />
          Object to the processing of your personal information. <br />
          Lodge a complaint with a supervisory authority. <br />
          To exercise your rights, please contact us at{" "}
          <a href="contact.bulletflow@gmail.com">
            contact.bulletflow@gmail.com
          </a>
          <br />
          <br />
          <h3>Changes to This Privacy Policy</h3>
          We may update this privacy policy from time to time. We will notify
          you of any changes by posting the new privacy policy on our website.
          <br />
          <br />
          <h3>Contact Us</h3>
          If you have any questions or concerns about this privacy policy,
          please contact us at{" "}
          <a href="contact.bulletflow@gmail.com">
            contact.bulletflow@gmail.com
          </a>
          <br />
          By using our bullet journal application, you agree to the terms of
          this privacy policy.
        </p>
      </StyledContainer>
    </Fragment>
  );
};

export default Privacy;
