import { useContext, useState } from "react";

import Button from "components/atoms/Button";
import Dialog from "components/molecules/Dialog";
import DialogButtonGroup from "components/molecules/DialogButtonGroup";
import DialogContainer from "components/molecules/DialogContainer";
import Tag from "components/molecules/Tag";
import { CalendarContext } from "contexts/CalendarContext";
import { useTranslation } from "react-i18next";

interface EditCalendarsSelectedDialogProps {
  handleClose: () => void;
}

const EditCalendarsSelectedDialog = ({
  handleClose,
}: EditCalendarsSelectedDialogProps) => {
  const { t } = useTranslation();

  const { calendars, synchronizeCalendar } = useContext(CalendarContext);

  const [calendarsSelected, setCalendarsSelected] = useState<any[]>(calendars);

  const removeCalendar = (id: string) => {
    const newCalendarsSelected = calendarsSelected.filter(
      (calendar) => calendar.id !== id,
    );
    setCalendarsSelected(newCalendarsSelected);
  };

  const handleSynchronize = () => {
    synchronizeCalendar(calendarsSelected);
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} title={t("calendars_synchronization")}>
      <DialogContainer>
        <div>
          {calendarsSelected.map((calendar) => (
            <Tag
              key={calendar.id}
              loading={false}
              text={calendar.summary}
              handleDelete={() => removeCalendar(calendar.id)}
            />
          ))}
        </div>
        <DialogButtonGroup>
          <Button handleClick={handleClose} text={t("cancel")} />
          <Button handleClick={handleSynchronize} text={t("synchronize")} />
        </DialogButtonGroup>
      </DialogContainer>
    </Dialog>
  );
};

export default EditCalendarsSelectedDialog;
