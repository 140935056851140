import { Fragment, useContext, useState } from "react";

import TrackersAndCalendarTabs from "components/molecules/TrackersAndCalendarTabs";
import EditCalendarsSelectedDialog from "components/organism/Calendar/EditCalendarsSelectedDialog";
import Calendar from "components/organism/Calendar/ViewWeekMonth";
import TrackersSettings from "components/organism/Trackers/TrackersSettings";
import Trackers from "components/template/CalendarAndTrackers/Trackers";
import { TimeContext } from "contexts/TimeContext";

import { StyledContainer, StyledTrackerSection } from "./styles";

export const TABLE_MODE = "table";
export const BARCHART_MODE = "barchart";
export const GRAPH_MODE = "graph";

export type TrackerModeType =
  | typeof TABLE_MODE
  | typeof BARCHART_MODE
  | typeof GRAPH_MODE;

const CalendarAndTrackers = () => {
  const { currentPeriodId } = useContext(TimeContext);

  const [blockToDisplay, setBlockToDisplay] = useState("trackers");

  const [currentTrackerMode, setCurrentTrackerMode] =
    useState<TrackerModeType>(TABLE_MODE);

  const [openTrackersSettings, setOpenTrackersSettings] = useState(false);

  const [openCalendarSettings, setOpenCalendarSettings] = useState(false);

  const toggleOpenTrackersSettings = () => {
    setOpenTrackersSettings(!openTrackersSettings);
  };

  const toggleOpenCalendarSettings = () => {
    setOpenCalendarSettings(!openCalendarSettings);
  };

  return (
    <Fragment>
      <StyledContainer>
        <TrackersAndCalendarTabs
          blockToDisplay={blockToDisplay}
          setBlockToDisplay={setBlockToDisplay}
          currentTrackerMode={currentTrackerMode}
          setCurrentTrackerMode={setCurrentTrackerMode}
          displayTrackerOptions={blockToDisplay === "trackers"}
          displayGraphOption={currentPeriodId === "months"}
          toggleOpenTrackersSettings={
            blockToDisplay === "trackers"
              ? toggleOpenTrackersSettings
              : toggleOpenCalendarSettings
          }
        />
        <StyledTrackerSection>
          {blockToDisplay === "trackers" && (
            <Trackers
              currentTrackerMode={currentTrackerMode}
              toggleOpenTrackersSettings={toggleOpenTrackersSettings}
            />
          )}
          {blockToDisplay === "calendar" && <Calendar />}
        </StyledTrackerSection>
      </StyledContainer>
      {openTrackersSettings && (
        <TrackersSettings onClose={toggleOpenTrackersSettings} />
      )}
      {openCalendarSettings && (
        <EditCalendarsSelectedDialog handleClose={toggleOpenCalendarSettings} />
      )}
    </Fragment>
  );
};

export default CalendarAndTrackers;
