import styled from "styled-components";

export const StyledGoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid rgb(218, 220, 224);
  color: #3c4043;
  font-size: 14px;
  cursor: pointer;
  gap: 8px;
  transition: background-color 0.3s ease;

  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;

  &:hover {
    background-color: #f8f9fa;
    border-color: #d2e3fc;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  padding: 0;
`;
