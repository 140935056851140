import { Fragment } from "react";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { blueGrey } from "@mui/material/colors";
import FadeIn from "components/molecules/FadeIn";
import { DateRangesEnum } from "contexts/TimeContext";
import { useIsMobile } from "customHooks";
import { useLocation } from "react-router-dom";

import {
  StyledContainer,
  StyledDateRange,
  StyledArrowsContainers,
} from "./styles";

type DateRangeInterface = {
  name: string;
  isActive: boolean;
};

type DateRangeSelectorInterface = {
  dateRanges: DateRangeInterface[];
  onSelectDateRange: (dateType: DateRangesEnum, name: string) => void;
  dateType: DateRangesEnum;
  previous?: () => void;
  next?: () => void;
  fadeIt?: boolean;
};

const DateRangeSelector = ({
  dateRanges,
  onSelectDateRange,
  dateType,
  previous,
  next,
  fadeIt,
}: DateRangeSelectorInterface) => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {fadeIt && <FadeIn />}
      <StyledContainer
        hasPreviousAndNext={!!previous && !!next ? true : false}
        isSecondLineSelector={!previous && !next}
        isMobile={isMobile}
      >
        {previous && next && (
          <StyledArrowsContainers>
            <div onClick={previous}>
              <KeyboardArrowLeftIcon
                sx={{ color: blueGrey[400] }}
                fontSize={isMobile ? "small" : "medium"}
              />
            </div>
            <div onClick={next}>
              <KeyboardArrowRightIcon
                sx={{ color: blueGrey[400] }}
                fontSize={isMobile ? "small" : "medium"}
              />
            </div>
          </StyledArrowsContainers>
        )}
        {dateRanges.map((dateRange: DateRangeInterface, i) => {
          if (pathname.includes("trackers") && i === 0 && !previous) {
            return null;
          }
          return (
            <StyledDateRange
              key={dateRange.name}
              isActive={dateRange.isActive}
              onClick={() => onSelectDateRange(dateType, dateRange.name)}
              isMobile={isMobile}
            >
              {dateRange.name}
            </StyledDateRange>
          );
        })}
      </StyledContainer>
      {fadeIt && <FadeIn />}
    </Fragment>
  );
};

export default DateRangeSelector;
