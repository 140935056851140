import { useContext, useMemo } from "react";

import { TrackersContext } from "contexts/TrackersContext";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
} from "recharts";

const QuartersGraph = () => {
  const { yearTrackers } = useContext(TrackersContext);

  const colors = scaleOrdinal(schemeCategory10).range();

  const formattedData = useMemo(() => {
    const formattedTrackersData: any[] | undefined = [];
    let someTrackersHaveTrackedDays = false;

    yearTrackers.forEach((monthTrackers, i) => {
      const monthData: any = {};
      monthTrackers.forEach((tracker) => {
        monthData[tracker.name] = tracker.completedDays.length || 0;
        if (tracker.completedDays.length > 0) {
          someTrackersHaveTrackedDays = true;
        }
      });

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[i];
      monthData.name = monthName;

      formattedTrackersData.push(monthData);
    });

    if (!someTrackersHaveTrackedDays) {
      return false;
    }

    return formattedTrackersData;
  }, [yearTrackers]);

  const trackerNames = useMemo(() => {
    const names: string[] = [];
    yearTrackers.forEach((monthTrackers) => {
      monthTrackers.forEach((tracker) => {
        if (names.includes(tracker.name)) return;
        names.push(tracker.name);
      });
    });

    return names;
  }, [yearTrackers]);

  if (!formattedData) return <div>No data to display</div>;

  return (
    <LineChart
      width={1050}
      height={600}
      data={formattedData}
      barSize={60}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {trackerNames.length &&
        trackerNames.map((dataKey: any, i: any) => (
          <Line
            key={i}
            strokeWidth={3}
            type="monotone"
            dataKey={dataKey}
            stroke={colors[i % colors.length]}
            activeDot={{ r: 8 }}
          />
        ))}
    </LineChart>
  );
};

export default QuartersGraph;
