import styled from "styled-components";

export const StyledDialogContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => !isMobile && "500px"};
  max-width: ${({ isMobile }) => isMobile && "100vw"};
  height: 400px;

  align-items: center;
  justify-content: center;
  padding: 18px;
`;

export const StyledDialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: large;
  font-weight: 500;
  margin-bottom: 4px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 15px;
  margin-left: -18px;
  margin-right: -18px;
  padding-left: 18px;
  padding-right: 18px;
`;

export const StyledCloseIconContainer = styled.div`
  cursor: pointer;
`;

export const StyledDialogContent = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ isMobile }) =>
    isMobile ? "calc(100vh - 85px)" : "calc(400px - 85px)"};
`;

export const StyledDialogButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  gap: 30px;
`;
