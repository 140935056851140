import styled from "styled-components";

export const StyledTopBarContainer = styled.div`
  border-bottom: 1px solid grey;
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.main.colors.primary};
  color: ${({ theme }) => theme.main.colors.secondary};
  padding: 0 35px;
`;

export const StyledLogo = styled.div`
  font-size: 20px;
  letter-spacing: 4px;
  cursor: pointer;
`;

export const StyledSignOut = styled.div`
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid grey;
  padding: 5px 10px;
`;

export const StyledNavItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px;
  font-weight: ${({ isActive }) => (isActive ? "500" : "300")};
  &:hover {
    background-color: #ffffff7d;
    transition: all 0.3s ease-in-out;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
`;
