import FadeIn from "components/molecules/FadeIn";
import styled from "styled-components";

export const StyledTask = styled.div<{ isComplete: boolean }>`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;
  padding: 10px 14px 15px 14px;
  margin-bottom: 5px;
  border-radius: 8px;
  color: ${(props) => props.theme.main.colors.primary};
  border: 1px solid ${(props) => props.theme.main.colors.primaryXLight};
  font-size: 14px;
  letter-spacing: 1px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  min-height: 15px;
  background-color: ${(props) =>
    props.isComplete
      ? props.theme.main.colors.bgTasksDone
      : props.theme.main.colors.bgTasksTodo};

  width: auto;
`;

export const StyledTaskTitle = styled.div<{ isComplete: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledTasksContainer = styled(FadeIn)<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.isMobile ? "40px" : "0")};
`;

export const StyledOptions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const StyledCategoryTaskContainer = styled.div<{ withMargin: boolean }>`
  margin-bottom: ${(props) => (props.withMargin ? "30px" : "0")};
`;

export const StyledSubtitle = styled.div`
  color: ${(props) => props.theme.main.colors.primary};
  font-size: 16px;
  letter-spacing: 1px;
  padding-bottom: 16px;
  font-weight: 600;
  width: 100%;
`;

export const StyledNoTasks = styled.div`
  color: ${(props) => props.theme.main.colors.primary};
  font-size: 16px;
  padding-bottom: 26px;
  width: 100%;
  font-weight: 300;
`;
