import { Fragment, useState } from "react";

import { useIsMobile } from "customHooks";
import { useTranslation } from "react-i18next";

import { StyledNewTask, StyledMobileTaskContainer } from "./styles";
import Button from "../Button";

interface NewTaskInterface {
  addTask: (task: NewTaskStateInterface) => void;
}

export interface NewTaskStateInterface {
  title: string;
  label: string;
}

const NewTask = ({ addTask }: NewTaskInterface) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const [newTask, setNewTask] = useState<NewTaskStateInterface>({
    title: "",
    label: "",
  });

  const handleNewTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTask({ ...newTask, title: e.target.value });
  };

  const handleSaveNewTask = (e: { key: string }) => {
    if (e.key === "Enter") {
      addTask(newTask);
      setNewTask({ title: "", label: "" });
    }
  };

  const handleAddNewTask = () => {
    addTask(newTask);
    setNewTask({ title: "", label: "" });
  };

  return (
    <Fragment>
      {isMobile ? (
        <StyledMobileTaskContainer>
          <StyledNewTask
            type="text"
            value={newTask.title}
            placeholder={t("add_new_backlog_task") as string}
            onChange={handleNewTaskChange}
            isMobile={isMobile}
          />
          <Button handleClick={handleAddNewTask} text={t("save")} />
        </StyledMobileTaskContainer>
      ) : (
        <StyledNewTask
          type="text"
          value={newTask.title}
          autoFocus
          placeholder={t("add_new_backlog_task") as string}
          onKeyPress={handleSaveNewTask}
          onChange={handleNewTaskChange}
          isMobile={isMobile}
        />
      )}
    </Fragment>
  );
};

export default NewTask;
