import styled from "styled-components";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: #f6f6f6;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
`;

const TestimonialList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
`;

const TestimonialItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px 40px;
  max-width: 300px;
  text-align: center;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  border-radius: 50%;
`;

const TestimonialText = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
`;

const TestimonialAuthor = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const Section3 = () => {
  return (
    <Container>
      <Title>Les gens célèbres qui n'utilisent pas notre application</Title>
      <TestimonialList>
        <TestimonialItem>
          <Avatar
            src="https://randomuser.me/api/portraits/men/1.jpg"
            alt="Avatar 1"
          />
          <TestimonialText>
            "Je n'utilise pas cette application, mais si je le faisais, je
            serais probablement très satisfait."
          </TestimonialText>
          <TestimonialAuthor>- Barack Obama</TestimonialAuthor>
        </TestimonialItem>
        <TestimonialItem>
          <Avatar
            src="https://randomuser.me/api/portraits/women/2.jpg"
            alt="Avatar 2"
          />
          <TestimonialText>
            "Je ne sais même pas ce qu'est une application de bullet journal
            numérique, mais si j'en avais besoin, je ferais probablement mes
            recherches."
          </TestimonialText>
          <TestimonialAuthor>- Beyoncé</TestimonialAuthor>
        </TestimonialItem>
        <TestimonialItem>
          <Avatar
            src="https://randomuser.me/api/portraits/men/3.jpg"
            alt="Avatar 3"
          />
          <TestimonialText>
            "Je suis trop occupé à regarder des vidéos de chats sur Internet
            pour utiliser une application de bullet journal."
          </TestimonialText>
          <TestimonialAuthor>- Elon Musk</TestimonialAuthor>
        </TestimonialItem>
      </TestimonialList>
    </Container>
  );
};

export default Section3;
