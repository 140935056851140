export const DAYS_DASHBOARD_ROUTE = "/dashboard/days";
export const WEEKS_DASHBOARD_ROUTE = "/dashboard/weeks";
export const MONTHS_DASHBOARD_ROUTE = "/dashboard/months";
export const QUARTERS_DASHBOARD_ROUTE = "/dashboard/quarters";
export const YEARS_DASHBOARD_ROUTE = "/dashboard/years";

export const WEEK_TRACKERS_STATS = "/trackers/weeks";
export const MONTH_TRACKERS_STATS = "/trackers/months";
export const QUARTER_TRACKERS_STATS = "/trackers/quarters";
export const YEAR_TRACKERS_STATS = "/trackers/years";

export const PROFILE_ROUTE = "/profile";

export const LOGIN_ROUTE = "/authentication";

export const PRIVACY_ROUTE = "/privacy";
