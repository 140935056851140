import { Fragment, useContext } from "react";

import FadeIn from "components/molecules/FadeIn";
import { CalendarContext } from "contexts/CalendarContext";
import { GoogleCalendarEvent } from "types/calendar";

import CalendarEvent from "../../../molecules/CalendarEvent";

const CalendarDayEvents = () => {
  const { dailyEvents } = useContext(CalendarContext);

  return (
    <FadeIn>
      {!dailyEvents.length ? (
        <p>No events for today</p>
      ) : (
        <Fragment>
          {dailyEvents.map((event: GoogleCalendarEvent) => (
            <CalendarEvent key={event.id} event={event} />
          ))}
        </Fragment>
      )}
    </FadeIn>
  );
};

export default CalendarDayEvents;
