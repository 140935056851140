import { Fragment, useContext, useState } from "react";

import LoaderList from "components/atoms/LoaderList";
import TitleSection from "components/atoms/TitleSection";
import { CalendarContext } from "contexts/CalendarContext";
import { TimeContext } from "contexts/TimeContext";
import { useTranslation } from "react-i18next";

import CalendarMonthEvents from "./CalendarMonthEvents";
import CalendarWeekEvents from "./CalendarWeekEvents";
import EditCalendarsSelectedDialog from "./EditCalendarsSelectedDialog";
import SynchronizeCalendarsModal from "../SynchronizeCalendarsModal";

const Calendar = () => {
  const { t } = useTranslation();

  const { dateRanges } = useContext(TimeContext);
  const { calendarsLoading, hasCalendar } = useContext(CalendarContext);

  const { periodRange } = dateRanges;

  const [openEditCalendarsSelected, setOpenEditCalendarsSelected] =
    useState(false);

  const toggleOpenEditCalendarsSelected = () =>
    setOpenEditCalendarsSelected(!openEditCalendarsSelected);

  return (
    <Fragment>
      <div>
        <TitleSection>{t("calendar_appointments")}</TitleSection>

        {calendarsLoading || hasCalendar === null ? (
          <LoaderList />
        ) : hasCalendar ? (
          <Fragment>
            {periodRange[1].isActiveWithDelay && <CalendarWeekEvents />}
            {periodRange[2].isActiveWithDelay && <CalendarMonthEvents />}
          </Fragment>
        ) : (
          <SynchronizeCalendarsModal />
        )}
        {openEditCalendarsSelected && (
          <EditCalendarsSelectedDialog
            handleClose={toggleOpenEditCalendarsSelected}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Calendar;
