import { Fragment } from "react";

import CloseIcon from "@mui/icons-material/Close";

import { StyledDeleteIconContainer, StyledTagContainer } from "./styles";

type Props = {
  loading: boolean;
  text: string;
  handleDelete: () => void;
};

const Tag = ({ loading, text, handleDelete }: Props) => {
  return (
    <StyledTagContainer>
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <Fragment>
          <div>{text}</div>
          <StyledDeleteIconContainer onClick={handleDelete}>
            <CloseIcon fontSize="small" />
          </StyledDeleteIconContainer>
        </Fragment>
      )}
    </StyledTagContainer>
  );
};

export default Tag;
