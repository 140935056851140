import { useContext } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { TimeContext } from "contexts/TimeContext";
import { DateRangesEnum } from "contexts/TimeContext";

const DateRangeSelectorWeek = () => {
  const { dateRanges, onSelectDateRange, onChangeCurrentWeeks } =
    useContext(TimeContext);
  const { weeks } = dateRanges;

  const handleSwitchPreviousWeeks = () => {
    onChangeCurrentWeeks(-1);
  };

  const handleSwitchNextWeeks = () => {
    onChangeCurrentWeeks(1);
  };

  return (
    <DateRangeSelector
      previous={handleSwitchPreviousWeeks}
      next={handleSwitchNextWeeks}
      dateRanges={weeks}
      onSelectDateRange={onSelectDateRange}
      dateType={DateRangesEnum.weeks}
      fadeIt
    />
  );
};

export default DateRangeSelectorWeek;
