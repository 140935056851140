import { useEffect, useState, createContext, useContext } from "react";

import { NewTaskStateInterface } from "components/atoms/NewTask";
import { UserContext } from "contexts/UserContext";
import {
  getTasks as _getTasks,
  getBacklogTasks as _getBacklogTasks,
  addTask as _addTask,
  addTaskToBacklog as _addTaskToBacklog,
  toggleCompleteTask as _toggleCompleteTask,
  editTask as _editTask,
  deleteTask as _deleteTask,
  removeTaskFromBacklog as _removeTaskFromBacklog,
} from "fire/tasks";
import { getSingularPeriod, getNextDayYyyyMmDdFormat } from "helpers/date";

import { AlertContext } from "./AlertContext";
import { TimeContext } from "./TimeContext";

interface TasksProviderInterface {
  children: React.ReactNode;
}

export interface TaskInterface {
  id: string;
  title: string;
  label: string;
  completed: boolean;
}

interface TasksInterface {
  days: TaskInterface[];
  weeks: TaskInterface[];
  months: TaskInterface[];
  quarters: TaskInterface[];
  years: TaskInterface[];
}

interface TasksContextInterface {
  newTaskLoading: boolean;
  tasks: TasksInterface;
  tasksLoading: boolean;
  addTask: (task: NewTaskStateInterface) => void;
  toggleCompleteTask: (id: string, shouldBeComplete: boolean) => void;
  editTask: (id: string, task: NewTaskStateInterface) => void;
  deleteTask: (id: string) => void;
  passToNextPeriod: (task: TaskInterface) => void;
  addTaskToBacklog: (task: NewTaskStateInterface) => void;
  backlogTasks: TaskInterface[];
  removeTaskFromBacklog: (taskId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TasksContext = createContext<TasksContextInterface>();

const TasksProvider = ({ children }: TasksProviderInterface) => {
  const [tasks, setTasks] = useState<TasksInterface>({
    days: [],
    weeks: [],
    months: [],
    quarters: [],
    years: [],
  });
  const [backlogTasks, setBacklogTasks] = useState<TaskInterface[]>([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [newTaskLoading, setNewTaskLoading] = useState(false);
  const { currentUser } = useContext(UserContext);
  const { currentPeriod, currentPeriodId } = useContext(TimeContext);

  const { setAlert } = useContext(AlertContext);

  const getTasks = async () => {
    setTasksLoading(true);

    let currentTasks: TaskInterface[] = [];
    try {
      currentTasks = await _getTasks(
        currentUser.uid,
        currentPeriod[getSingularPeriod(currentPeriodId)],
        currentPeriodId,
      );
      setTasks({ ...tasks, [currentPeriodId]: currentTasks });
    } catch (error) {
      setTasks({ ...tasks, [currentPeriodId]: currentTasks });
    }
    setTasksLoading(false);
  };

  const getBacklogTasks = async () => {
    try {
      const backlogTasks = await _getBacklogTasks(currentUser.uid);
      // order alphabetically
      const orderedBacklogTasks = backlogTasks.sort((a, b) =>
        a.title.localeCompare(b.title),
      );
      setBacklogTasks(orderedBacklogTasks);
    } catch (error) {
      console.log(error);
    }
  };

  const addTask = async (task: NewTaskStateInterface) => {
    setNewTaskLoading(true);

    try {
      const newTask = await _addTask(
        currentUser.uid,
        currentPeriod[getSingularPeriod(currentPeriodId)],
        task,
        currentPeriodId,
      );
      setTasks({
        ...tasks,
        [currentPeriodId]: [...tasks[currentPeriodId], newTask],
      });
      const backLogTaskToDelete = backlogTasks.find(
        (t) => t.title === task.title,
      );
      if (backLogTaskToDelete) {
        await _removeTaskFromBacklog(currentUser.uid, backLogTaskToDelete.id);
        setBacklogTasks(
          backlogTasks.filter((t) => t.title !== backLogTaskToDelete.title),
        );
      }
    } catch (error) {
      console.log(error);
    }
    setNewTaskLoading(false);
  };

  const addTaskToBacklog = async (task: NewTaskStateInterface) => {
    try {
      const newTask = await _addTaskToBacklog(currentUser.uid, task);
      setBacklogTasks([...backlogTasks, newTask]);
    } catch (error) {
      console.log(error);
    }
  };

  const removeTaskFromBacklog = async (taskId: string) => {
    try {
      await _removeTaskFromBacklog(currentUser.uid, taskId);
      setBacklogTasks(backlogTasks.filter((task) => task.id !== taskId));
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCompleteTask = async (id: string, shouldBeComplete: boolean) => {
    try {
      await _toggleCompleteTask(
        currentUser.uid,
        currentPeriod[getSingularPeriod(currentPeriodId)],
        id,
        shouldBeComplete,
        currentPeriodId,
      );
      setTasks({
        ...tasks,
        [currentPeriodId]: [
          ...tasks[currentPeriodId].map((task: TaskInterface) => {
            if (task.id === id) {
              return { ...task, completed: shouldBeComplete };
            }
            return task;
          }),
        ],
      });
    } catch (error) {
      setAlert("An error occurred while toggling the task. Please try again.");
    }
  };

  const editTask = async (id: string, task: NewTaskStateInterface) => {
    try {
      await _editTask(
        currentUser.uid,
        currentPeriod[getSingularPeriod(currentPeriodId)],
        id,
        task,
        currentPeriodId,
      );
      getTasks();
    } catch (error) {
      setAlert("An error occurred while editing the task. Please try again.");
    }
  };

  const deleteTask = async (id: string) => {
    try {
      await _deleteTask(currentUser.uid, id, currentPeriodId);
      setTasks({
        ...tasks,
        [currentPeriodId]: [
          ...tasks[currentPeriodId].filter(
            (task: TaskInterface) => task.id !== id,
          ),
        ],
      });
    } catch (error) {
      setAlert("An error occurred while deleting the task. Please try again.");
    }
  };

  const passToNextPeriod = async (task: TaskInterface) => {
    try {
      await _deleteTask(currentUser.uid, task.id, currentPeriodId);
      const trackerDay = currentPeriod[getSingularPeriod(currentPeriodId)];
      const trackerNextDay = getNextDayYyyyMmDdFormat(trackerDay);
      await _addTask(
        currentUser.uid,
        trackerNextDay,
        {
          title: task.title,
          label: task.label,
        },
        currentPeriodId,
      );
      const updatedDailyTasks = tasks.days.filter((t) => t.id !== task.id);
      setTasks({
        ...tasks,
        days: updatedDailyTasks,
      });
    } catch (error) {
      console.log("Salut à toi jeune entrepreneur");
    }
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    getTasks();
    getBacklogTasks();
    // eslint-disable-next-line
  }, [currentPeriod, currentUser]);

  return (
    <TasksContext.Provider
      value={{
        newTaskLoading,
        tasks,
        tasksLoading,
        addTask,
        toggleCompleteTask,
        editTask,
        deleteTask,
        passToNextPeriod,
        addTaskToBacklog,
        backlogTasks,
        removeTaskFromBacklog,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export default TasksProvider;
