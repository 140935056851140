import styled from "styled-components";

export const StyledContainer = styled.div<{
  hasPreviousAndNext: boolean;
  isSecondLineSelector: boolean;
  isMobile: boolean;
}>`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.main.colors.primaryXLight};
  margin: 20px 20px 0px 20px;
  padding: ${(props) => (props.isMobile ? "0px 10px" : "0px")};
`;

export const StyledDateRange = styled.div<{
  isActive: boolean;
  isMobile: boolean;
}>`
  padding-left: 8px;
  padding-right: 8px;
  color: ${(props) => props.theme.main.colors.primary};
  cursor: pointer;
  font-size: ${(props) => (props.isMobile ? "14px" : "16px")};
  font-weight: ${(props) => (props.isActive ? "600" : "normal")};
  padding-bottom: 14px;
  border-bottom: ${(props) =>
    props.isActive
      ? `3px solid ${props.theme.main.colors.primary}`
      : "transparent"};
  letter-spacing: 1px;
  transition: border-bottom-color 0.5s ease-in-out;
`;

export const StyledArrowsContainers = styled.div`
  display: flex;
  padding-right: 10px;
  cursor: pointer;
`;
