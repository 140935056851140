import React from "react";

import { StyledButtonGroup } from "./styles";

type Props = {
  children: React.ReactNode;
};

const ButtonGroup = ({ children }: Props) => (
  <StyledButtonGroup>{children}</StyledButtonGroup>
);

export default ButtonGroup;
