import { Fragment } from "react";

import GoogleButton from "components/atoms/GoogleButton";
import { auth } from "fire/init";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { StyledSignInButtonContainer } from "./styles";

const Authentication = () => {
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      console.log("user logged in");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <StyledSignInButtonContainer>
        <GoogleButton text="Login with Google" handleClick={loginWithGoogle} />
      </StyledSignInButtonContainer>
    </Fragment>
  );
};

export default Authentication;
