import styled from "styled-components";

export const StyledContainer = styled.div`
  margin-left: 20px;
`;

export const StyledTrackerSection = styled.div<{
  largeContainer?: boolean;
  children: React.ReactNode;
}>`
  padding: 20px 30px;
  border-left: 1px solid #eef5fc14
  display: flex
  align-items: center;
  flex-direction: column;
  width: 40vw;
`;
