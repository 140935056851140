import { useEffect, useState, createContext, useContext, useMemo } from "react";

import { TimeContext } from "contexts/TimeContext";
import { UserContext } from "contexts/UserContext";
import {
  getTracks as _getTracks,
  getQuarterTrackers as _getQuarterTrackers,
  addTracker as _addTracker,
  deleteTracker as _deleteTracker,
  toggleCompleteTrack as _toggleCompleteTrack,
  toggleWeekTracker as _toggleWeekTracker,
  setSameTrackersThanLastMonth as _setSameTrackersThanLastMonth,
} from "fire/trackers";
import {
  WeekInterface,
  getNextSevenDaysNumber,
  getPreviousMonthYyyyMmFormat,
  lastThreeMonths,
} from "helpers/date";

interface TrackersProviderInterface {
  children: React.ReactNode;
}

export interface TrackInterface {
  name: string;
  label: string;
  id: string;
  completedDays: number[];
}

interface TrackersContextInterface {
  tracksLoading: boolean | null;
  trackers: TrackInterface[];
  weekTrackers: TrackInterface[];
  toggleCompleteTrack: (
    trackerId: string,
    day: number,
    tracker: TrackInterface,
  ) => void;
  toggleCompleteWeekTrack: (
    trackerId: string,
    day: number,
    tracker: TrackInterface,
    month: string,
  ) => void;
  addTracker: (name: string) => void;
  trackerSettingLoading: boolean;
  deleteTracker: (trackerId: string) => Promise<boolean>;
  currentTrackerMonth: {
    number: number;
    id: string;
  };
  weekDays: {
    dayNames: string[];
    dayNumbers: number[];
    monthKeys: string[];
  };
  getQuarterTrackers: () => void;
  quarterTrackers: TrackInterface[][];
  currentTrackerQuarter: string[];
  currentTrackerWeek: WeekInterface;
  yearTrackers: TrackInterface[][];
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TrackersContext = createContext<TrackersContextInterface>({});

const TrackersProvider = ({ children }: TrackersProviderInterface) => {
  const { dateRanges, currentPeriodId } = useContext(TimeContext);
  const { currentUser } = useContext(UserContext);

  const { days, months, weeks, quarters, years } = dateRanges;

  const currentDayTracker = useMemo(() => {
    const currentDay = days.find((m) => m.isActive) || days[0];
    return currentDay;
  }, [days]);

  const currentTrackerWeek = useMemo((): WeekInterface | any => {
    const currentWeek = weeks.find((m) => m.isActive) || weeks[0];

    return currentWeek;
    // eslint-disable-next-line
  }, [weeks]);

  const weekDays = useMemo(() => {
    return getNextSevenDaysNumber(currentTrackerWeek.start);
  }, [currentTrackerWeek]);

  const currentTrackerMonth = useMemo(() => {
    const currentMonth = months.find((m) => m.isActive) || months[0];
    return {
      number: currentMonth.number,
      id: currentMonth.id,
    };
  }, [months]);

  const currentTrackerLastQuarter = useMemo(() => {
    const currentMonth = months.find((m) => m.isActive) || months[0];
    return lastThreeMonths(currentMonth.id);
  }, [months]);

  const currentTrackerQuarter = useMemo(() => {
    const currenQuarter = quarters.find((m) => m.isActive) || quarters[0];
    return lastThreeMonths(currenQuarter.referenceMonth);
  }, [quarters]);

  const currentTrackerYearMonths = useMemo(() => {
    const months = [];
    const currentYear = years.find((m) => m.isActive) || years[0];
    for (let i = 1; i < 13; i++) {
      const month = i < 10 ? `0${i}` : `${i}`;
      const monthKey = `${currentYear.id}-${month}`;
      months.push(monthKey);
    }

    return months;
  }, [years]);

  const [tracksLoading, setTracksLoading] = useState<boolean | null>(null);
  // Month trackers
  const [trackers, setTrackers] = useState<TrackInterface[]>([]);
  //
  const [weekTrackers, setWeekTrackers] = useState<TrackInterface[]>([]);
  const [quarterTrackers, setQuarterTrackers] = useState<TrackInterface[][]>(
    [],
  );
  const [yearTrackers, setYearTrackers] = useState<TrackInterface[][]>([]);

  const [trackerSettingLoading, setTrackerSettingLoading] = useState(false);

  const getDayTrackers = async () => {
    setTracksLoading(true);
    try {
      const tracks = await _getTracks(
        currentUser.uid,
        currentDayTracker.monthKey,
      );
      setTrackers(tracks);
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const getWeekTrackers = async () => {
    setTracksLoading(true);
    try {
      const [firstday, lastday] = currentTrackerWeek.id.split("!");
      const monthOfFirstDayOfTheWeek = firstday.slice(0, 7);
      const monthOfLastDayOfTheWeek = lastday.slice(0, 7);

      if (monthOfFirstDayOfTheWeek === monthOfLastDayOfTheWeek) {
        const tracks = await _getTracks(
          currentUser.uid,
          monthOfFirstDayOfTheWeek,
        );
        const weeksTrackersFiltered = tracks.map((track) => {
          const completedDays = track.completedDays.filter((day) =>
            weekDays.dayNumbers.includes(day),
          );
          return {
            ...track,
            completedDays,
          };
        });

        setWeekTrackers(weeksTrackersFiltered);
      } else {
        const tracksOfFirstMonth = await _getTracks(
          currentUser.uid,
          monthOfFirstDayOfTheWeek,
        );

        const tracksOfLastMonth = await _getTracks(
          currentUser.uid,
          monthOfLastDayOfTheWeek,
        );

        const weeksTrackersFilteredOfFirstMonth = tracksOfFirstMonth.map(
          (track) => {
            const completedDays = track.completedDays.filter(
              (day) => weekDays.dayNumbers.includes(day) && day > 25,
            );
            return {
              ...track,
              completedDays,
            };
          },
        );

        const weeksTrackersFilteredOfLastMonth = tracksOfLastMonth.map(
          (track) => {
            const completedDays = track.completedDays.filter(
              (day) => weekDays.dayNumbers.includes(day) && day < 7,
            );
            return {
              ...track,
              completedDays,
            };
          },
        );

        const firstAndLastMonthMerge = weeksTrackersFilteredOfFirstMonth.map(
          (track) => {
            const completeDaysOfLastMonth =
              weeksTrackersFilteredOfLastMonth.find(
                (t) => t.id === track.id,
              )?.completedDays;

            return {
              ...track,
              completedDays: [
                ...track.completedDays,
                ...(completeDaysOfLastMonth || []),
              ],
            };
          },
        );

        const lastAndFirstMonthMerge = weeksTrackersFilteredOfLastMonth.map(
          (track) => {
            const completeDaysOfFirstMonth =
              weeksTrackersFilteredOfFirstMonth.find(
                (t) => t.id === track.id,
              )?.completedDays;

            return {
              ...track,
              completedDays: [
                ...track.completedDays,
                ...(completeDaysOfFirstMonth || []),
              ],
            };
          },
        );

        setWeekTrackers(
          firstAndLastMonthMerge.length >= lastAndFirstMonthMerge.length
            ? firstAndLastMonthMerge
            : lastAndFirstMonthMerge,
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const getMonthTrackers = async () => {
    setTracksLoading(true);
    try {
      const tracks = await _getTracks(currentUser.uid, currentTrackerMonth.id);
      setTrackers(tracks);

      if (tracks.length === 0) {
        //get trackers from previous month
        const trackersFromLastMonth = await _setSameTrackersThanLastMonth(
          currentUser.uid,
          currentTrackerMonth.id,
          getPreviousMonthYyyyMmFormat(currentTrackerMonth.id),
        );
        setTrackers(trackersFromLastMonth);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const getQuarterTrackers = async () => {
    setTracksLoading(true);
    try {
      const tracks = await _getQuarterTrackers(
        currentUser.uid,
        currentTrackerLastQuarter,
      );
      console.log("YOYO RIGHT SECOND ONE");

      setQuarterTrackers(tracks);
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const getQuartersForQuarterSection = async () => {
    setTracksLoading(true);

    try {
      const tracks = await _getQuarterTrackers(
        currentUser.uid,
        currentTrackerQuarter,
      );

      setQuarterTrackers(tracks);
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const getYearTrackers = async () => {
    setTracksLoading(true);

    try {
      const tracks = await _getQuarterTrackers(
        currentUser.uid,
        currentTrackerYearMonths,
      );

      setYearTrackers(tracks);
    } catch (error) {
      console.log(error);
    } finally {
      setTracksLoading(false);
    }
  };

  const toggleCompleteTrack = async (
    trackerId: string,
    day: number,
    tracker: TrackInterface,
  ) => {
    try {
      const completedDaysUpdated = await _toggleCompleteTrack(
        currentUser.uid,
        currentTrackerMonth.id,
        trackerId,
        day,
        tracker,
      );
      setTrackers((prev) => {
        return prev.map((track) => {
          if (track.id === trackerId) {
            return {
              ...track,
              completedDays: completedDaysUpdated,
            };
          }
          return track;
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCompleteWeekTrack = async (
    trackerId: string,
    day: number,
    tracker: TrackInterface,
    month: string,
  ) => {
    try {
      await _toggleWeekTracker(currentUser.uid, month, trackerId, day, tracker);
      setWeekTrackers((prev) => {
        return prev.map((track) => {
          if (track.id === trackerId) {
            return {
              ...track,
              completedDays: track.completedDays.includes(day)
                ? [...track.completedDays.filter((d) => d !== day)]
                : [...track.completedDays, day],
            };
          }
          return track;
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addTracker = async (name: string) => {
    setTrackerSettingLoading(true);
    try {
      const month =
        currentPeriodId === "days"
          ? currentDayTracker.monthKey
          : currentPeriodId === "weeks"
          ? currentTrackerWeek.monthName
          : currentTrackerMonth.id;

      const newTracker = await _addTracker(name, month, currentUser.uid);
      if (currentPeriodId === "weeks") {
        setWeekTrackers((prev) => {
          return [...prev, newTracker];
        });
      } else {
        setTrackers((prev) => {
          return [...prev, newTracker];
        });
      }
    } catch (error) {
      console.log(error);
    }
    setTrackerSettingLoading(false);
  };

  const deleteTracker = async (trackerId: string) => {
    const month =
      currentPeriodId === "days"
        ? currentDayTracker.monthKey
        : currentPeriodId === "weeks"
        ? currentTrackerWeek.monthName
        : currentTrackerMonth.id;

    try {
      await _deleteTracker(currentUser.uid, month, trackerId);
      if (currentPeriodId === "weeks") {
        setWeekTrackers((prev) => {
          return prev.filter((track) => track.id !== trackerId);
        });
      } else {
        setTrackers((prev) => {
          return prev.filter((track) => track.id !== trackerId);
        });
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (!currentUser || currentPeriodId !== "days") {
      return;
    }
    console.log("GET DAY TRACKERS");
    getDayTrackers();
    // eslint-disable-next-line
  }, [currentDayTracker, currentUser, currentPeriodId]);

  useEffect(() => {
    if (!currentUser || currentPeriodId !== "weeks") {
      return;
    }
    console.log("GET WEEK TRACKERS");

    getWeekTrackers();
    // eslint-disable-next-line
  }, [currentTrackerWeek, currentUser, currentPeriodId]);

  useEffect(() => {
    if (!currentUser || currentPeriodId !== "months") {
      return;
    }
    console.log("GET MONTH TRACKERS");

    getMonthTrackers();
    // eslint-disable-next-line
  }, [currentTrackerMonth, currentUser, currentPeriodId]);

  useEffect(() => {
    if (!currentUser || currentPeriodId !== "quarters") {
      return;
    }

    console.log("GET QUARTERS QUARTERS TRACKERS");
    getQuartersForQuarterSection();
    // eslint-disable-next-line
  }, [currentTrackerQuarter, currentUser, currentPeriodId]);

  useEffect(() => {
    if (!currentUser || currentPeriodId !== "years") {
      return;
    }

    console.log("GET YEARS TRACKERS");
    getYearTrackers();
    // eslint-disable-next-line
  }, [currentTrackerYearMonths, currentUser, currentPeriodId]);

  return (
    <TrackersContext.Provider
      value={{
        tracksLoading,
        weekTrackers,
        trackers,
        toggleCompleteTrack,
        addTracker,
        trackerSettingLoading,
        deleteTracker,
        currentTrackerMonth,
        weekDays,
        getQuarterTrackers,
        quarterTrackers,
        currentTrackerQuarter,
        toggleCompleteWeekTrack,
        yearTrackers,
        currentTrackerWeek,
      }}
    >
      {children}
    </TrackersContext.Provider>
  );
};

export default TrackersProvider;
