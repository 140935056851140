import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { StyledSectionTitle, StyledSettingsIconContainer } from "./styles";

interface TitleInterface {
  children: React.ReactNode;
  settingClick?: () => void;
  withTabs?: boolean;
}

const TitleSection = ({ children, settingClick, withTabs }: TitleInterface) => (
  <StyledSectionTitle>
    <div>{children}</div>
    {!!settingClick && (
      <StyledSettingsIconContainer withTabs={withTabs}>
        <SettingsOutlinedIcon fontSize="small" onClick={settingClick} />
      </StyledSettingsIconContainer>
    )}
  </StyledSectionTitle>
);

export default TitleSection;
