import FadeIn from "components/molecules/FadeIn";

import BarChart from "./BarChart";
import Graph from "./Graph";
import { YearsTrackersContainer } from "./styles";

export const BARCHART_MODE = "barchart";
export const GRAPH_MODE = "graph";

export type TrackerModeType = typeof BARCHART_MODE | typeof GRAPH_MODE;
interface QuarterTrackersProps {
  currentTrackerOption: typeof BARCHART_MODE | typeof GRAPH_MODE;
}

const QuarterTrackers = ({ currentTrackerOption }: QuarterTrackersProps) => {
  return (
    <YearsTrackersContainer>
      {currentTrackerOption === BARCHART_MODE && (
        <FadeIn>
          <BarChart />
        </FadeIn>
      )}
      {currentTrackerOption === GRAPH_MODE && (
        <FadeIn>
          <Graph />
        </FadeIn>
      )}
    </YearsTrackersContainer>
  );
};

export default QuarterTrackers;
