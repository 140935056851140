import styled from "styled-components";

export const StyledTrackerNamesContainer = styled.div`
  display: flex;
  margin-left: 46px;
`;

export const StyledTrackerName = styled.div<{ isLast: boolean }>`
  color: ${({ theme }) => theme.main.colors.primary};
  font-size: 14px;
  font-weight: 600;

  width: 12px;
  word-wrap: break-word;
  text-transform: uppercase;
  text-align: justify;
  border-left: 1px solid ${({ theme }) => theme.main.colors.primary};
  padding: 8px;
  border-bottom: 2px solid ${({ theme }) => theme.main.colors.primary};
  border-top: 1px solid ${({ theme }) => theme.main.colors.primary};
  border-right: ${(props) =>
    props.isLast && `1px solid ${props.theme.main.colors.primary}`};
  height: 126px;
  overflow: hidden;
`;
