import { ChangeEvent } from "react";

import {
  StyledInputGroup,
  StyledInputTitle,
  StyledInputTextContainer,
  StyledInputIconContainer,
  StyledInputText,
} from "./styles";

interface InputTextOrNumberPropsInterface {
  inputTitle?: string;
  name: string;
  type?: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>, name: string) => void;
  disabled?: boolean;
  small?: false;
  largeTitle?: boolean;
  maxLength?: number;
  placeholder?: string;
  turnOffAutoFilling?: boolean;
  icon?: any;
  onPressEnter?: () => void;
  autoFocus?: boolean;
}

const InputTextOrNumber = ({
  inputTitle,
  name,
  type = "text",
  value,
  onChange,
  disabled,
  small = false,
  largeTitle,
  maxLength,
  placeholder = "",
  turnOffAutoFilling,
  icon,
  onPressEnter,
  autoFocus,
}: InputTextOrNumberPropsInterface) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, name);
  };

  const handlePressEnter = (e: any) => {
    if (e.key === "Enter" && !!onPressEnter) {
      onPressEnter();
    }
  };

  return (
    <StyledInputGroup>
      {inputTitle && (
        <StyledInputTitle largeTitle={largeTitle}>
          {inputTitle}
        </StyledInputTitle>
      )}
      <StyledInputTextContainer icon={icon}>
        {icon && <StyledInputIconContainer>{icon}</StyledInputIconContainer>}
        <StyledInputText
          small={small}
          disabled={!!disabled}
          icon={icon}
          name={name}
          value={value}
          maxLength={maxLength && maxLength}
          type={type ? type : "text"}
          onChange={handleOnChange}
          placeholder={placeholder}
          onKeyDown={handlePressEnter}
          // chrome ignores autoComplete="off" so we need to use "new-password"
          autoComplete={turnOffAutoFilling ? "new-password" : "on"}
          autoFocus={autoFocus}
        />
      </StyledInputTextContainer>
    </StyledInputGroup>
  );
};

export default InputTextOrNumber;
