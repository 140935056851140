import { useState, useContext } from "react";

import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Paper } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { LayoutContext } from "contexts/LayoutContext";
import { useNavigate } from "react-router-dom";
import { DAYS_DASHBOARD_ROUTE, PROFILE_ROUTE } from "routes";

const navigation = ["tasks", "calendar", "trackers"];

const MyBottomNavigation = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const { handleChangeSectionToDisplay } = useContext(LayoutContext);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);

    if (newValue === 3) {
      navigate(PROFILE_ROUTE);
    } else if (!window.location.pathname.includes("/dashboard")) {
      navigate(DAYS_DASHBOARD_ROUTE);
    } else {
      handleChangeSectionToDisplay(navigation[newValue]);
    }
  };

  return (
    <Paper sx={{ position: "fixed", bottom: 0, width: 1.0 }} elevation={3}>
      <BottomNavigation showLabels value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Tasks"
          icon={<FormatListBulletedIcon />}
        />
        <BottomNavigationAction label="Calendar" icon={<CalendarMonthIcon />} />
        <BottomNavigationAction label="Trackers" icon={<AutoGraphIcon />} />
        <BottomNavigationAction label="Profile" icon={<PermIdentityIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default MyBottomNavigation;
