import React from "react";

import { StyledSubtitle } from "./styles";

type Props = {
  children: React.ReactNode;
};

const DialogSubtitle = ({ children }: Props) => {
  return <StyledSubtitle>{children}</StyledSubtitle>;
};

export default DialogSubtitle;
