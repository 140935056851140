import styled from "styled-components";

export const StyledTrackerDay = styled.div<{ isTicked: boolean }>`
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 8px;
  padding: 0 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid ${(props) => (props.isTicked ? "#18BA92" : "#707070")};
  &:hover {
    background: ${(props) => !props.isTicked && "lightblue"};
  }
`;
