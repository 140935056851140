import { Fragment, useState } from "react";

import ClickAwayListener from "@mui/base/ClickAwayListener";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useIsMobile } from "customHooks";
import { Popover } from "react-tiny-popover";

import {
  StyledCalendarEventContainer,
  StyledVisioLink,
  StyledTitleAndDescription,
  StyledPopper,
} from "./styles";

type CalendarEventProps = {
  event: any;
  noBorder?: boolean;
};

const CalendarEvent = ({ event, noBorder }: CalendarEventProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const isMobile = useIsMobile();

  const start =
    new Date(event.start.dateTime).getHours().toString().padStart(2, "0") +
    ":" +
    new Date(event.start.dateTime).getMinutes().toString().padStart(2, "0");
  const end =
    new Date(event.end.dateTime).getHours().toString().padStart(2, "0") +
    ":" +
    new Date(event.end.dateTime).getMinutes().toString().padStart(2, "0");

  const isFutureEvent = new Date() < new Date(event.start.dateTime);
  const isPastEvent = new Date() > new Date(event.end.dateTime);

  const openVisioLink = () => {
    window.open(event.conferenceData?.entryPoints[0].uri, "_blank");
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setIsPopoverOpen(false);
  };

  const addLinksToText = (text: string) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const chunks = text.split(regex);

    return (
      <Fragment>
        {chunks.map((chunk, index) => {
          if (chunk.match(regex)) {
            return (
              <a
                key={index}
                href={chunk}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunk}
              </a>
            );
          }
          return chunk;
        })}
      </Fragment>
    );
  };

  return (
    <StyledCalendarEventContainer
      isFutureEvent={isFutureEvent || !!noBorder}
      noBorder={noBorder}
      isMobile={isMobile}
    >
      <StyledTitleAndDescription>
        <div>
          {start} - {end}
        </div>
        <div>
          {event.description && (
            <div onClick={() => setIsPopoverOpen(true)}>
              <div style={{ cursor: "pointer" }} onClick={handleClick}></div>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Popover
                  isOpen={isPopoverOpen}
                  positions={["top"]} // preferred positions by priority
                  content={
                    <StyledPopper>
                      {addLinksToText(event.description)}
                    </StyledPopper>
                  }
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  >
                    <InfoOutlinedIcon />
                  </div>
                </Popover>
              </ClickAwayListener>
            </div>
          )}
        </div>
      </StyledTitleAndDescription>
      <div>{event.summary}</div>
      {event.conferenceData?.entryPoints[0].uri && !isPastEvent && (
        <StyledVisioLink onClick={openVisioLink} isFutureEvent={isFutureEvent}>
          Accéder à la conférence
        </StyledVisioLink>
      )}
    </StyledCalendarEventContainer>
  );
};

export default CalendarEvent;
