"use client";
import { createContext } from "react";

import { toast } from "react-toastify";

interface AlertContextInterface {
  children: React.ReactNode;
}

interface AlertContextType {
  setAlert: (message: string, type?: string) => void;
}

const initialState: AlertContextType = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAlert: () => {},
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AlertContext = createContext<AlertContextType>(initialState);

const AlertProvider = ({ children }: AlertContextInterface) => {
  const setAlert = (title: string, type?: string) => {
    if (!type || type === "success") {
      toast.success(title, {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } else {
      toast.warning("title", {
        position: "bottom-right",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };
  return (
    <AlertContext.Provider value={{ setAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
