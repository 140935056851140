import { useContext } from "react";

import { TrackersContext } from "contexts/TrackersContext";

import { StyledTrackerName, StyledTrackerNamesContainer } from "./styles";

const TrackerColumns = () => {
  const { weekTrackers } = useContext(TrackersContext);

  return (
    <StyledTrackerNamesContainer>
      {weekTrackers.map((tracker, i) => (
        <StyledTrackerName
          key={tracker.id}
          isLast={i === weekTrackers.length - 1}
        >
          {tracker.name}
        </StyledTrackerName>
      ))}
    </StyledTrackerNamesContainer>
  );
};

export default TrackerColumns;
