import { useContext } from "react";

import { UserContext } from "contexts/UserContext";
import { Navigate, RouteProps } from "react-router-dom";

const AuthenticatedRoute = ({ children }: RouteProps): JSX.Element => {
  const { currentUser } = useContext(UserContext);

  return <>{currentUser ? children : <Navigate to="/authentication" />}</>;
};

export default AuthenticatedRoute;
