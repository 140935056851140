import { useContext, useMemo } from "react";

import { TrackersContext } from "contexts/TrackersContext";

import { StyledDaysOfMonthContainer, StyledDayNumber } from "./styles";

const DaysAndTracksRows = () => {
  const {
    toggleCompleteWeekTrack,
    weekDays,
    weekTrackers,
    currentTrackerWeek,
  } = useContext(TrackersContext);

  const today = new Date().getDate();

  const days = useMemo(() => {
    return weekDays.dayNames.map((dayName, index) => ({
      dayNumber: weekDays.dayNumbers[index],
      monthKey: weekDays.monthKeys[index],
      dayName,
    }));
  }, [weekDays]);

  const isCurrentMonth = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const currentDate = `${year}-${month}`;
    return currentDate === currentTrackerWeek.monthName;
  }, [currentTrackerWeek]);

  return (
    <StyledDaysOfMonthContainer>
      <div>
        {days.map((day, i) => (
          <StyledDayNumber
            isFirst={i === 0}
            isActive={today === day.dayNumber && isCurrentMonth}
            isLeftColumn
            isDay
            key={`${day.dayNumber}-day`}
          >
            <div>
              {day.dayName} {day.dayNumber}
            </div>
          </StyledDayNumber>
        ))}
      </div>
      {weekTrackers.map((tracker, i) => (
        <div key={`tracker-${tracker.id}`}>
          {days.map((day, y) => (
            <StyledDayNumber
              key={`tracker-${tracker.id}-day-${day.dayNumber}`}
              isFirst={y === 0}
              isLast={i === weekTrackers.length - 1}
              onClick={() =>
                toggleCompleteWeekTrack(
                  tracker.id,
                  day.dayNumber,
                  tracker,
                  day.monthKey,
                )
              }
            >
              {tracker.completedDays.includes(day.dayNumber) ? "X" : ""}
            </StyledDayNumber>
          ))}
        </div>
      ))}
    </StyledDaysOfMonthContainer>
  );
};

export default DaysAndTracksRows;
