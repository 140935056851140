import styled from "styled-components";

export const StyledButton = styled.button`
  background-color: white;
  border: 1px solid #18ba92;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 10px 20px;
  transition: border-color 0.5s ease-in-out, color 0.1s ease-in-out;
  width: 100%;
  &:hover {
    background-color: white;
    border-color: #3363ff;
  }
`;
