import { Fragment, useContext } from "react";

// import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
// import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import DoneIcon from "@mui/icons-material/Done";
// import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import LoaderList from "components/atoms/LoaderList";
import { TasksContext, TaskInterface } from "contexts/TasksContext";
import { TimeContext } from "contexts/TimeContext";
import { PluralPeriods } from "helpers/date";
import { useTranslation } from "react-i18next";

import {
  StyledTask,
  StyledTaskTitle,
  StyledOptions,
  StyledCategoryTaskContainer,
  StyledSubtitle,
  StyledNoTasks,
} from "./styles";

interface TasksListInterface {
  openEditaskDialog: (task: TaskInterface) => void;
  taskType: PluralPeriods;
}

const TaskList = ({ openEditaskDialog, taskType }: TasksListInterface) => {
  const { t } = useTranslation();

  const {
    tasks,
    toggleCompleteTask,
    deleteTask,
    newTaskLoading,
    passToNextPeriod,
  } = useContext(TasksContext);

  const { currentPeriodId } = useContext(TimeContext);

  const currentTasks = tasks[taskType];

  console.log("currentTasks", currentTasks.length > 0);

  return (
    <Fragment>
      <StyledCategoryTaskContainer
        withMargin={
          currentTasks &&
          currentTasks.filter((task) => !task.completed).length > 0
        }
      >
        <StyledSubtitle>{t("tasks_to_do")}</StyledSubtitle>
        {currentTasks.filter((task) => !task.completed).length > 0 ? (
          currentTasks
            .filter((task) => !task.completed)
            .map((task) => (
              <StyledTask key={task.id} isComplete={false}>
                <StyledTaskTitle
                  isComplete={false}
                  onClick={() => toggleCompleteTask(task.id, true)}
                >
                  <div>{task.title}</div>
                </StyledTaskTitle>
                <StyledOptions>
                  <ModeEditOutlinedIcon
                    onClick={() => openEditaskDialog(task)}
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                  />
                  <DeleteOutlineIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTask(task.id)}
                    fontSize="small"
                  />
                  {currentPeriodId === "days" && (
                    <Fragment>
                      <KeyboardArrowRightIcon
                        onClick={() => passToNextPeriod(task)}
                        style={{
                          cursor: "pointer",
                          marginBottom: "-15px",
                        }}
                      />
                    </Fragment>
                  )}
                </StyledOptions>
              </StyledTask>
            ))
        ) : (
          <StyledNoTasks>{t("no_tasks_to_do")}</StyledNoTasks>
        )}
      </StyledCategoryTaskContainer>
      {newTaskLoading && <LoaderList />}
      <StyledSubtitle>{t("tasks_done")}</StyledSubtitle>
      {currentTasks.filter((task) => task.completed).length > 0 ? (
        currentTasks
          .filter((task) => task.completed)
          .map((task) => (
            <StyledTask key={task.id} isComplete={true}>
              <StyledTaskTitle
                isComplete={true}
                onClick={() => toggleCompleteTask(task.id, false)}
              >
                {task.title}
              </StyledTaskTitle>
              <StyledOptions>
                <ModeEditOutlinedIcon
                  onClick={() => openEditaskDialog(task)}
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                />
                <DeleteOutlineIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => deleteTask(task.id)}
                />
              </StyledOptions>
            </StyledTask>
          ))
      ) : (
        <StyledNoTasks>{t("no_tasks_done")}</StyledNoTasks>
      )}
    </Fragment>
  );
};

export default TaskList;
