import { useContext } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { TimeContext } from "contexts/TimeContext";
import { DateRangesEnum } from "contexts/TimeContext";

const DateRangeSelectorQuarters = () => {
  const { dateRanges, onSelectDateRange, onChangeCurrentQuarters } =
    useContext(TimeContext);
  const { quarters } = dateRanges;

  const handleSwitchPreviousQuarters = () => {
    onChangeCurrentQuarters(-1);
  };

  const handleSwitchNextQuarters = () => {
    onChangeCurrentQuarters(1);
  };

  return (
    <DateRangeSelector
      previous={handleSwitchPreviousQuarters}
      next={handleSwitchNextQuarters}
      dateRanges={quarters}
      onSelectDateRange={onSelectDateRange}
      dateType={DateRangesEnum.quarters}
      fadeIt
    />
  );
};

export default DateRangeSelectorQuarters;
