import styled from "styled-components";

export const StyledNewTask = styled.input<{ isMobile: boolean }>`
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.main.colors.primaryXLight};
  width: 100%;
  padding-left: 8px;
  border-radius: 8px;
  color: ${(props) => props.theme.main.colors.primary};
  font-size: 14px;
  letter-spacing: 1px;
  outline: none;
  background-color: inherit;
  font-size: 14px;
  letter-spacing: 1px;
  height: 45px;
  background-color: ${(props) => props.theme.main.colors.bgTasks};
  margin: auto;
`;

export const StyledMobileTaskContainer = styled.div`
  display: grid;
  grid-template-columns: 10fr 3fr;
  grid-gap: 16px;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  border-radius: 8px;
  color: ${(props) => props.theme.main.colors.primary};
  justify-content: space-between;
  min-height: 15px;
  background-color: ${(props) => props.theme.main.colors.bgTasks};
  width: auto;
`;
