import { NewTaskStateInterface } from "components/atoms/NewTask";
import { TaskInterface } from "contexts/TasksContext";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";

import { uniqueId } from "./helpers";
import { db } from "./init";

export const getTasks = async (
  uid: string,
  date: string,
  periodId: string,
): Promise<TaskInterface[]> => {
  const tasks: any[] = [];

  const requestedCollection = collection(db, `tasks/${uid}/${periodId}`);
  const formattedDate =
    periodId === "days" ? Timestamp.fromDate(new Date(date)) : date;

  const response = await getDocs(
    query(requestedCollection, where("date", "==", formattedDate)),
  );

  response.forEach((doc) => {
    const data = doc.data();
    tasks.push({ ...data, id: doc.id });
  });

  return tasks;
};

export const addTask = async (
  uid: string,
  date: string,
  task: NewTaskStateInterface,
  periodId: string,
) => {
  const formattedDate =
    periodId === "days" ? Timestamp.fromDate(new Date(date)) : date;

  const title = task.title.charAt(0).toUpperCase() + task.title.slice(1);
  const taskId = uniqueId();
  await setDoc(doc(db, "tasks", uid, periodId, taskId), {
    completed: false,
    title: title,
    label: task.label,
    date: formattedDate,
  });
  return {
    completed: false,
    title: title,
    label: task.label,
    id: taskId,
  };
};

export const addTaskToBacklog = async (
  uid: string,
  task: NewTaskStateInterface,
) => {
  const title = task.title.charAt(0).toUpperCase() + task.title.slice(1);
  const taskId = uniqueId();
  await setDoc(doc(db, "tasks", uid, "backlog", taskId), {
    completed: false,
    title: title,
    label: task.label,
  });
  return {
    completed: false,
    title: title,
    label: task.label,
    id: taskId,
  };
};

export const getBacklogTasks = async (
  uid: string,
): Promise<TaskInterface[]> => {
  const tasks: any[] = [];

  const requestedCollection = collection(db, `tasks/${uid}/backlog`);

  const response = await getDocs(requestedCollection);

  response.forEach((doc) => {
    const data = doc.data();
    tasks.push({ ...data, id: doc.id });
  });

  return tasks;
};

export const removeTaskFromBacklog = async (uid: string, taskId: string) => {
  await deleteDoc(doc(db, "tasks", uid, "backlog", taskId));
};

export const toggleCompleteTask = async (
  uid: string,
  date: string,
  taskId: string,
  shouldBeComplete: boolean,
  periodId: string,
) => {
  await updateDoc(doc(db, "tasks", uid, periodId, taskId), {
    completed: shouldBeComplete,
  });
};

export const editTask = async (
  uid: string,
  date: string,
  taskId: string,
  task: NewTaskStateInterface,
  periodId: string,
) => {
  await updateDoc(doc(db, "tasks", uid, periodId, taskId), {
    title: task.title.charAt(0).toUpperCase() + task.title.slice(1),
    label: task.label,
  });
};

export const deleteTask = async (
  uid: string,
  taskId: string,
  periodId: string,
) => {
  await deleteDoc(doc(db, "tasks", uid, periodId, taskId));
};
