import { useContext } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { DateRangesEnum, TimeContext } from "contexts/TimeContext";
import { useNavigate, useLocation } from "react-router-dom";

const DateRangeSelectorPeriodType = () => {
  const { dateRanges, onSelectDateRange } = useContext(TimeContext);

  const { periodRange } = dateRanges;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleSelectDateRange = (dateType: DateRangesEnum, name: string) => {
    onSelectDateRange(dateType, name);
    navigate(
      `/${
        pathname.includes("dashboard") ? "dashboard" : "trackers"
      }/${name.toLocaleLowerCase()}s`,
    );
  };

  return (
    <DateRangeSelector
      dateRanges={periodRange}
      onSelectDateRange={handleSelectDateRange}
      dateType={DateRangesEnum.periodRange}
      fadeIt
    />
  );
};

export default DateRangeSelectorPeriodType;
