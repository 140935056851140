import { Fragment, useMemo, useState } from "react";

import DateRangeSelectorMonths from "components/molecules/DateRangeSelectorMonth";
import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import StatSwitcher from "components/molecules/StatSwitcher";
import MonthTrackers from "components/organism/Trackers/MonthTrackers";
import {
  TABLE_MODE,
  GRAPH_MODE,
  BARCHART_MODE,
  TrackerModeType,
} from "components/template/CalendarAndTrackers";
import TrackersStatTemplate from "components/template/TrackersStats";

const DashboardWeek = () => {
  const toggleOpenTrackersSettings = () => {
    console.log("gnjrkegnker");
  };

  const [trackerOptions, setTrackersOptions] = useState([
    {
      label: "Bar Chart",
      value: BARCHART_MODE,
      isActive: true,
    },
    {
      label: "Graphics",
      value: GRAPH_MODE,
      isActive: false,
    },
    {
      label: "Table",
      value: TABLE_MODE,
      isActive: false,
    },
  ]);

  const onChangeStatOption = (value: TrackerModeType) => {
    const newTrackerOptions = trackerOptions.map((option) => {
      if (option.value === value) {
        return {
          ...option,
          isActive: true,
        };
      }

      return {
        ...option,
        isActive: false,
      };
    });

    setTrackersOptions(newTrackerOptions);
  };

  const currentTrackerOption = useMemo(() => {
    return trackerOptions.find((option) => option.isActive)?.value;
  }, [trackerOptions]);

  return (
    <Fragment>
      <DateRangeSelectorPeriodType />
      <DateRangeSelectorMonths />
      <TrackersStatTemplate>
        <StatSwitcher
          trackerOptions={trackerOptions}
          onChangeStatOption={onChangeStatOption}
        />
        <MonthTrackers
          currentTrackerMode={currentTrackerOption}
          toggleOpenTrackersSettings={toggleOpenTrackersSettings}
        />
      </TrackersStatTemplate>
    </Fragment>
  );
};

export default DashboardWeek;
