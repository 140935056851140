import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f5f5f5;
  min-height: 100vh;
  height: 100%;
`;

export const ProfileSection = styled.div`
  width: 100%;
  max-width: 700px;
  margin-bottom: 40px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const SectionHeader = styled.h3`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #555;
`;

export const SectionItem = styled.div<{ withMarginBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: ${({ withMarginBottom }) => withMarginBottom && "40px"};
`;

export const ItemLabel = styled.span`
  font-size: 16px;
  padding-left: 4p;
  font-weight: bold;
  color: #555;
`;

export const ItemValue = styled.span`
  font-size: 16px;
  color: #888;
`;
