import { useState } from "react";

import Button from "components/atoms/Button";
import InputTextOrNumber from "components/atoms/Input";
import Dialog from "components/molecules/Dialog";
import ButtonGroup from "components/molecules/DialogButtonGroup";
import DialogContainer from "components/molecules/DialogContainer";
import { TaskInterface } from "contexts/TasksContext";

type EditTaskDialogInterface = {
  editTask: (id: string, task: TaskInterface) => void;
  handleClose: () => void;
  initialTaskToEdit: TaskInterface;
};

const EditTaskDialog = ({
  initialTaskToEdit,
  editTask,
  handleClose,
}: EditTaskDialogInterface) => {
  const [taskToEdit, setTaskToEdit] =
    useState<TaskInterface>(initialTaskToEdit);

  const handleNewTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskToEdit({ ...taskToEdit, title: e.target.value });
  };

  const handleEditTask = () => {
    editTask(taskToEdit.id, taskToEdit);
    handleClose();
  };

  return (
    <Dialog title={`Task to edit - ${taskToEdit.title}`} onClose={handleClose}>
      <DialogContainer>
        <InputTextOrNumber
          largeTitle
          inputTitle="Title of the task"
          name="taskToEdit"
          value={taskToEdit.title}
          onChange={handleNewTaskChange}
          onPressEnter={handleEditTask}
          autoFocus
        />
        <ButtonGroup>
          <Button handleClick={handleClose} text={"Cancel"} />
          <Button handleClick={handleEditTask} text={"Edit task"} />
        </ButtonGroup>
      </DialogContainer>
    </Dialog>
  );
};

export default EditTaskDialog;
