import { useContext } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { TimeContext } from "contexts/TimeContext";
import { DateRangesEnum } from "contexts/TimeContext";

const DateRangeSelectorMonths = () => {
  const { dateRanges, onSelectDateRange, onChangeCurrentMonths } =
    useContext(TimeContext);
  const { months } = dateRanges;

  const handleSwitchPreviousMonths = () => {
    onChangeCurrentMonths(-1);
  };

  const handleSwitchNextMonths = () => {
    onChangeCurrentMonths(1);
  };

  return (
    <DateRangeSelector
      previous={handleSwitchPreviousMonths}
      next={handleSwitchNextMonths}
      dateRanges={months}
      onSelectDateRange={onSelectDateRange}
      dateType={DateRangesEnum.months}
      fadeIt
    />
  );
};

export default DateRangeSelectorMonths;
