import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "components/atoms/Button";
import { useIsMobile } from "customHooks";

import {
  StyledDialogHeader,
  StyledDialogContainer,
  StyledCloseIconContainer,
  StyledDialogContent,
  StyledDialogButtonContainer,
} from "./styles";

type CustomDialogProps = {
  children?: React.ReactNode;
  onClose: () => void;
  title: string;
  open?: boolean;
  dialogButtons?: {
    text: string;
    action: () => void;
  }[];
};

const CustomDialog = ({
  children,
  onClose,
  title,
  open = true,
  dialogButtons,
}: CustomDialogProps) => {
  const [initialTitle] = useState(title);

  const isMobile = useIsMobile();

  const fullScreen = useMediaQuery("(max-width:600px)");

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <StyledDialogContainer isMobile={isMobile}>
        <StyledDialogHeader>
          <div>{initialTitle}</div>
          <StyledCloseIconContainer onClick={onClose}>
            <CloseIcon />
          </StyledCloseIconContainer>
        </StyledDialogHeader>
        <br />
        <StyledDialogContent isMobile={isMobile}>
          <div>{children}</div>
          <StyledDialogButtonContainer>
            {dialogButtons?.map((dialogButton) => (
              <Button
                key={dialogButton.text}
                handleClick={dialogButton.action}
                text={dialogButton.text}
              />
            ))}
          </StyledDialogButtonContainer>
        </StyledDialogContent>
      </StyledDialogContainer>
    </Dialog>
  );
};

export default CustomDialog;
