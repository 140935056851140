import CircularProgress from "@mui/material/CircularProgress";

import { StyledLoaderContainer } from "./styles";

const Loader = () => {
  return (
    <StyledLoaderContainer>
      <CircularProgress color="inherit" />
    </StyledLoaderContainer>
  );
};

export default Loader;
