import { Fragment } from "react";

import { useIsMobile } from "customHooks";
import { useLocation } from "react-router-dom";

import BottomNavigation from "./BottomNavigation";
import TopBar from "./TopBar";

const Navigation = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  return (
    <Fragment>
      {isMobile ? (
        pathname.includes("auth") || pathname === "/" ? (
          <TopBar />
        ) : (
          <BottomNavigation />
        )
      ) : (
        <TopBar />
      )}
    </Fragment>
  );
};

export default Navigation;
