import { useContext, useMemo } from "react";

import { TrackersContext } from "contexts/TrackersContext";
import { TrackInterface } from "contexts/TrackersContext";
import { getDaysInMonth } from "helpers/date";

import { StyledDaysOfMonthContainer, StyledDayNumber } from "./styles";

const DaysAndTracksRows = () => {
  const { trackers, toggleCompleteTrack, currentTrackerMonth } =
    useContext(TrackersContext);
  const today = new Date().getDate();

  const handleToggleCompleteTrack = (
    trackerId: string,
    day: number,
    tracker: TrackInterface,
  ) => {
    toggleCompleteTrack(trackerId, day, tracker);
  };

  const days = useMemo(() => {
    return getDaysInMonth(
      currentTrackerMonth.number,
      parseInt(currentTrackerMonth.id.substring(0, 4)),
    );
  }, [currentTrackerMonth]);

  const isCurrentMonth = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const currentDate = `${year}-${month}`;
    return currentDate === currentTrackerMonth.id;
  }, [currentTrackerMonth]);

  return (
    <StyledDaysOfMonthContainer>
      <div>
        {days.map((day, i) => (
          <StyledDayNumber
            isFirst={i === 0}
            isActive={today === day.dayNumber && isCurrentMonth}
            isLeftColumn
            isDay
            key={`${day.dayNumber}-day`}
          >
            <div>
              {day.dayName} {day.dayNumber}
            </div>
          </StyledDayNumber>
        ))}
      </div>
      {trackers.map((tracker, i) => (
        <div key={`tracker-${tracker.id}`}>
          {days.map((day, y) => (
            <StyledDayNumber
              key={`tracker-${tracker.id}-day-${day.dayNumber}`}
              isFirst={y === 0}
              isLast={i === trackers.length - 1}
              onClick={() =>
                handleToggleCompleteTrack(tracker.id, day.dayNumber, tracker)
              }
            >
              {tracker.completedDays.includes(day.dayNumber) ? "X" : ""}
            </StyledDayNumber>
          ))}
        </div>
      ))}
    </StyledDaysOfMonthContainer>
  );
};

export default DaysAndTracksRows;
