import styled from "styled-components";

export const StyledDayTitle = styled.div`
  color: ${(props) => props.theme.main.colors.primary};
  font-size: 18px;
  letter-spacing: 1px;
  padding-bottom: 12px;
`;

export const StyledDayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.main.colors.primaryXLight};
`;

export const StyledNoEvents = styled.div<{ isPast?: boolean }>`
  padding-bottom: 16px;
  padding-left: 8px;
  color: ${(props) => props.isPast && "red"};
`;

export const StyledDaysContainer = styled.div<{ isMobile: boolean }>`
  max-height: 72vh;
  overflow-y: ${({ isMobile }) => isMobile && "scroll"};
`;
