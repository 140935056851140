import { Fragment, useContext } from "react";

import DateRangeSelectorDays from "components/molecules/DateRangeSelectorDays";
import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import Calendar from "components/organism/Calendar/ViewDay";
import TasksContainer from "components/organism/TasksContainer";
import DaysTrackers from "components/organism/Trackers/DaysTrackers";
import DashboardTemplate from "components/template/Dashboard";
import { LayoutContext } from "contexts/LayoutContext";
import { TimeContext } from "contexts/TimeContext";
import { useIsMobile } from "customHooks";

interface DateRangeStateInterface {
  name: string;
  isActive: boolean;
  isActiveWithDelay: boolean;
}

export type DateRangesStateInterface = DateRangeStateInterface[];

const DaysDashboard = () => {
  const { dateRanges } = useContext(TimeContext);
  const { mobileSectionToDisplay } = useContext(LayoutContext);

  const { periodRange } = dateRanges;

  const isMobile = useIsMobile();
  return (
    <Fragment>
      {isMobile ? (
        <Fragment>
          <DateRangeSelectorPeriodType />
          <DateRangeSelectorDays />
          <DashboardTemplate>
            <div>
              {mobileSectionToDisplay === "tasks" && (
                <TasksContainer
                  taskType="days"
                  visible={periodRange[0].isActiveWithDelay}
                />
              )}
              {mobileSectionToDisplay === "calendar" && <Calendar />}
              {mobileSectionToDisplay === "trackers" && <DaysTrackers />}
            </div>
          </DashboardTemplate>
        </Fragment>
      ) : (
        <Fragment>
          <DateRangeSelectorPeriodType />
          <DateRangeSelectorDays />
          <DashboardTemplate>
            <TasksContainer
              taskType="days"
              visible={periodRange[0].isActiveWithDelay}
            />
            <Calendar />
            <DaysTrackers />
          </DashboardTemplate>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DaysDashboard;
