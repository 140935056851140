import styled from "styled-components";

export const StyledHomeContainer = styled.div<{
  isQuarterOrYear?: boolean;
  isMobile: boolean;
}>`
  background-color: ${(props) => props.theme.main.colors.secondary};
  display: grid;
  grid-template-columns: 1fr 10fr;
  height: 100%;
  margin: 0;
  gap: 40px;
  padding: 40px 30px;
`;

export const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
