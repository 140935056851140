import { useContext, useEffect } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { TimeContext } from "contexts/TimeContext";
import { DateRangesEnum } from "contexts/TimeContext";

const DateRangeSelectorDays = () => {
  const { dateRanges, onSelectDateRange, onChangeCurrentDays } =
    useContext(TimeContext);
  const { days } = dateRanges;

  const handleSwitchPreviousDays = () => {
    onChangeCurrentDays(-1);
  };

  const handleSwitchNextDays = () => {
    onChangeCurrentDays(1);
  };

  useEffect(() => {
    console.log(days);
  }, [days]);

  return (
    <DateRangeSelector
      previous={handleSwitchPreviousDays}
      next={handleSwitchNextDays}
      dateRanges={days}
      onSelectDateRange={onSelectDateRange}
      dateType={DateRangesEnum.days}
      fadeIt
    />
  );
};

export default DateRangeSelectorDays;
