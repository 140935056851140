import GoogleIcon from "assets/authentication/GoogleCalendarIcon";

import { StyledGoogleButton, StyledIconContainer } from "./styles";

type GoogleButtonProps = {
  handleClick: () => void;
  text: string;
};

const GoogleCalendarButton = ({ handleClick, text }: GoogleButtonProps) => {
  return (
    <StyledGoogleButton onClick={handleClick}>
      <StyledIconContainer>
        <GoogleIcon />
      </StyledIconContainer>
      {text}
    </StyledGoogleButton>
  );
};

export default GoogleCalendarButton;
