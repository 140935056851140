"use client";
import { createContext, useState } from "react";

interface LayoutContextInterface {
  children: React.ReactNode;
}

interface AlertContextType {
  handleChangeSectionToDisplay: (section: string) => void;
  mobileSectionToDisplay: string;
}

const initialState: AlertContextType = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  handleChangeSectionToDisplay: (section: string) => {},
  mobileSectionToDisplay: "",
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LayoutContext = createContext<LayoutContextType>(initialState);

const LayoutProvider = ({ children }: LayoutContextInterface) => {
  const [mobileSectionToDisplay, setMobileSectionToDisplay] =
    useState<string>("tasks");

  const handleChangeSectionToDisplay = (section: string) => {
    setMobileSectionToDisplay(section);
  };

  return (
    <LayoutContext.Provider
      value={{ mobileSectionToDisplay, handleChangeSectionToDisplay }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
