import calendar from "assets/home/calendar.png";
import todos from "assets/home/todos.png";
import trackers from "assets/home/trackers.png";
import styled from "styled-components";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: #fff;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px 40px;
  max-width: 300px;
  text-align: center;
`;

const Icon = styled.img`
  width: 180px;
  height: 180px;
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
`;

const Section2 = () => {
  return (
    <Container>
      <Title>Les fonctionnalités principales</Title>
      <FeatureList>
        <FeatureItem>
          <Icon src={todos} alt="Todo" />
          <FeatureTitle>Todo Lists</FeatureTitle>
          <FeatureDescription>
            Organisez vos tâches par jours, semaines, mois, trimestres, années.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <Icon src={calendar} alt="Synchronisation Google Calendar" />
          <FeatureTitle>Synchronisation à vos Calendriers</FeatureTitle>
          <FeatureDescription>
            Synchronisez votre calendrier Google avec l'application pour ne rien
            manquer.
          </FeatureDescription>
        </FeatureItem>
        <FeatureItem>
          <Icon src={trackers} alt="Tracking d'habitudes" />
          <FeatureTitle>Tracking d'habitudes</FeatureTitle>
          <FeatureDescription>
            Visualisez vos habitudes sur différentes périodes (semaines, mois,
            trimestres).
          </FeatureDescription>
        </FeatureItem>
      </FeatureList>
    </Container>
  );
};

export default Section2;
