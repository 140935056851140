import { useContext } from "react";

import Button from "components/atoms/Button";
import LoaderList from "components/atoms/LoaderList";
import FadeIn from "components/molecules/FadeIn";
import {
  BARCHART_MODE,
  TABLE_MODE,
} from "components/template/CalendarAndTrackers";
import { TrackersContext } from "contexts/TrackersContext";
import { useIsMobile } from "customHooks";

import BarChart from "./BarChart";
import DaysAndTrackRows from "./DaysAndTrackRows";
import {
  StyledMonthTableContainer,
  StyledWeekTrackersContainer,
} from "./styles";
import TrackerNames from "./TrackerNames";

interface WeekTrackersProps {
  currentTrackerMode: string | false;
  toggleOpenTrackersSettings?: () => void;
}

const WeekTrackers = ({
  currentTrackerMode,
  toggleOpenTrackersSettings,
}: WeekTrackersProps) => {
  const { weekTrackers, tracksLoading } = useContext(TrackersContext);

  const isMobile = useIsMobile();

  if (tracksLoading || tracksLoading === null) return <LoaderList />;

  if (weekTrackers.length === 0 && toggleOpenTrackersSettings)
    return (
      <Button handleClick={toggleOpenTrackersSettings} text="Add trackers" />
    );

  return (
    <StyledWeekTrackersContainer isMobile={isMobile}>
      {currentTrackerMode === TABLE_MODE && (
        <FadeIn>
          <TrackerNames />
          <StyledMonthTableContainer>
            <DaysAndTrackRows />
          </StyledMonthTableContainer>
        </FadeIn>
      )}
      {currentTrackerMode === BARCHART_MODE && (
        <FadeIn>
          <BarChart />
        </FadeIn>
      )}
    </StyledWeekTrackersContainer>
  );
};

export default WeekTrackers;
