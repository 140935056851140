import styled from "styled-components";

export const StyledCalendarEventContainer = styled.div<{
  isFutureEvent: boolean;
  noBorder?: boolean;
  isMobile: boolean;
}>`
  border-bottom: ${(props) =>
    `1px solid ${props.theme.main.colors.primaryXLight}`};
  border-radius: 8px;
  padding: 10px 5px;
  margin: ${(props) => (props.isMobile ? "6px 0px" : "6px 0px")};
  color: ${(props) =>
    props.isFutureEvent
      ? props.theme.main.colors.primary
      : props.theme.main.colors.primary};
  background-color: ${(props) => props.theme.main.colors.bgTasks};
  background-color: ${(props) =>
    props.isFutureEvent
      ? props.theme.main.colors.bgTasksTodo
      : props.theme.main.colors.bgTasksDone};
`;

export const StyledTitleAndDescription = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledVisioLink = styled.div<{ isFutureEvent: boolean }>`
  color: ${(props) => (props.isFutureEvent ? "#18BA92" : "")};
  border: 1px solid ${({ theme }) => theme.main.colors.primary};
  border-radius: 8px;
  font-size: 14px !important;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 14px;
  letter-spacing: 1px;
  transition: border-bottom-color 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
`;

export const StyledPopper = styled.div`
  padding: 10px;
  max-width: 300px;
  background-color: ${(props) => props.theme.main.colors.secondary};
  color: ${(props) => props.theme.main.colors.primary};
  border-radius: 8px;
  word-wrap: break-word;
  position: absolute;
  left: 15px;
`;
