import { useContext, useState } from "react";

import Button from "components/atoms/Button";
import LoaderList from "components/atoms/LoaderList";
import TitleSection from "components/atoms/TitleSection";
import { TrackersContext } from "contexts/TrackersContext";
import { useIsMobile } from "customHooks";
import { useTranslation } from "react-i18next";

import { StyledTrackerSection } from "./styles";
import TrackerList from "./TrackerList";
import TrackersSettings from "../TrackersSettings";

const Trackers = () => {
  const { t } = useTranslation();

  const { tracksLoading, trackers } = useContext(TrackersContext);

  const isMobile = useIsMobile();

  const [openTrackersSettings, setOpenTrackersSettings] = useState(false);

  const toggleOpenTrackersSettings = () => {
    setOpenTrackersSettings(!openTrackersSettings);
  };

  return (
    <StyledTrackerSection largeContainer={false} isMobile={isMobile}>
      <TitleSection settingClick={toggleOpenTrackersSettings}>
        {t("trackers")}
      </TitleSection>
      {tracksLoading || tracksLoading === null ? (
        <LoaderList />
      ) : trackers.length === 0 ? (
        <Button handleClick={toggleOpenTrackersSettings} text="Add trackers" />
      ) : (
        <TrackerList />
      )}
      {openTrackersSettings && (
        <TrackersSettings onClose={toggleOpenTrackersSettings} />
      )}
    </StyledTrackerSection>
  );
};

export default Trackers;
