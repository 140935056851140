import styled from "styled-components";

export const StyledInputTextContainer = styled.div<{ icon: boolean }>`
  position: ${(props) => props.icon && "relative"};
  display: ${(props) => props.icon && "flex"};
`;

export const StyledInputIconContainer = styled.span`
  position: absolute;
  align-items: center;
  top: 53%;
  left: 16px;
  transform: translateY(-50%);
`;

export const StyledInput = styled.input`
  border: 1px solid ${({ theme }) => theme.main.colors.primary};
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  padding-left: 12px;
  color: black;
`;

export const StyledInputText = styled(StyledInput)<{
  disabled: boolean;
  small: boolean;
  icon: boolean;
  theme?: any;
}>`
  background: ${(props) => props.disabled && "#f3f5f9"};
  color: ${(props) => props.disabled && props.theme.main.colors.secondary};
  width: ${(props) => props.small && "50px"};
  padding-left: ${(props) => props.icon && "42px"};
`;

export const StyledInputGroup = styled.div`
  margin-bottom: 14px;
  width: 100%;

  .MuiSelect-select.MuiSelect-select {
    border: 1px solid #d1d5dd;
    border-radius: 8px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 15px;
    font-family: Inter, sans-serif;
  }

  .MuiSelect-select:focus {
    background-color: white;
    color: black;
  }

  .MuiInput-underline::before {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: none;
  }

  .MuiInput-underline::after {
    border: none;
  }

  .MuiSelect-icon {
    right: 15px;
  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding-bottom: 0;
  }

  input[type="checkbox"] {
    transform: scale(1.3);
    margin-right: 15px !important;
    filter: grayscale(0.7);
  }
`;

export const StyledInputTitle = styled.div<{ largeTitle?: boolean }>`
  font-size: ${(props) => (props.largeTitle ? "16px" : "12px")};
  color: ${(props) => (props.largeTitle ? "black" : "#747c88")};
  font-weight: ${(props) => props.largeTitle && "400"};
  padding-bottom: ${(props) => props.largeTitle && "5px"};
  padding-top: ${(props) => props.largeTitle && "5px"};
  line-height: 24px;
  padding-bottom: 16px;
`;
