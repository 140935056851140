import styled from "styled-components";

export const StyledSectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.main.colors.primary};
  font-size: 16px;
  letter-spacing: 1px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 28px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.main.colors.primary};
`;

export const StyledSettingsIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding-top: 16px;
  margin-bottom: -10px;
`;

export const StyledTrackersButtonsContainer = styled.div`
  padding-right: 30px;
  display: flex;
  gap: 10px;
`;

export const StyledButtonsContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  color: ${({ theme }) => theme.main.colors.primary};
  grid-gap: 10px;
  display: flex;
  gap: 30px;
`;

export const StyledContainer = styled.div`
  margin-left: 20px;
`;

export const StyledButton = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  border-bottom: ${(props) =>
    props.isActive ? `3px solid ${props.theme.main.colors.primary}` : "none"};
  padding-bottom: 14px;
  margin-bottom: -16px;
`;
