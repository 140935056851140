import Dialog from "components/molecules/Dialog";

type DeleteUserDialogProps = {
  handleClose: () => void;
  title: string;
  deleteUser: () => void;
};

const DeleteUserDialog = ({
  handleClose,
  title,
  deleteUser,
}: DeleteUserDialogProps) => {
  const dialogButtons = [
    {
      text: "Cancel",
      action: handleClose,
    },
    {
      text: "Delete",
      action: deleteUser,
    },
  ];

  return (
    <Dialog onClose={handleClose} title={title} dialogButtons={dialogButtons}>
      Are you sure you want to delete your account?
    </Dialog>
  );
};

export default DeleteUserDialog;
