import { useIsMobile } from "customHooks";

import { StyledHomeContainer } from "./styles";

interface DashboardProps {
  children: React.ReactNode;
  isQuarterOrYear?: boolean;
}

const Dashboard = ({ children, isQuarterOrYear }: DashboardProps) => {
  const isMobile = useIsMobile();
  return (
    <StyledHomeContainer isQuarterOrYear={isQuarterOrYear} isMobile={isMobile}>
      {children}
    </StyledHomeContainer>
  );
};

export default Dashboard;
