import { QuerySnapshot, DocumentData } from "@firebase/firestore";

export const formatFirebaseTasks = (
  firebaseData: QuerySnapshot<DocumentData>,
) => {
  const tasks: any = [];
  firebaseData.forEach((doc) => {
    const task = { ...doc.data(), id: doc.id };
    tasks.push(task);
  });

  return tasks;
};

export const uniqueId = (): string => {
  return Date.now().toString(36) + Math.random().toString(36);
};
