import axios from "axios";
import { setDoc, doc } from "firebase/firestore";
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from "firebase/functions";

import { GET_GOOGLE_CALENDAR_EP } from "./googleCalendarEps";
import { db } from "./init";

const functions = getFunctions();

export const getSelectedCalendars = async () => {
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001); // DEBUG
  const getSelectedCalendarsCall = httpsCallable(
    functions,
    "getSelectedCalendars",
  );

  return await getSelectedCalendarsCall();
};

export const initGoogleCalendar = async (code: string) => {
  // connectFunctionsEmulator(functions, "127.0.0.1", 5001); // DEBUG
  const initGoogleCalendarCall = httpsCallable(
    functions,
    "configureGoogleCalendarSettings",
  );

  return await initGoogleCalendarCall({
    code: code,
  });
};

export const getCalendars = async (token: string) => {
  return await axios.get(GET_GOOGLE_CALENDAR_EP, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const storeCalendars = async (uid: string, calendars: any[]) => {
  return await setDoc(
    doc(db, "calendars", "googleCalendar", uid, "calendars"),
    {
      basicInformations: calendars,
    },
  );
};
