import { Fragment, useState } from "react";

import { TaskInterface } from "contexts/TasksContext";
import { useIsMobile } from "customHooks";
import { useTranslation } from "react-i18next";

import { StyledNewTask, StyledMobileTaskContainer } from "./styles";
import Button from "../Button";

interface NewTaskInterface {
  addTask: (task: NewTaskStateInterface) => void;
  backlogTasks: TaskInterface[];
}

export interface NewTaskStateInterface {
  title: string;
  label: string;
}

const NewTask = ({ addTask, backlogTasks }: NewTaskInterface) => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const [newTask, setNewTask] = useState<NewTaskStateInterface>({
    title: "",
    label: "",
  });

  const handleNewTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTask({ ...newTask, title: e.target.value });
  };

  const handleSaveNewTask = (e: { key: string }) => {
    if (e.key === "Enter") {
      addTask(newTask);
      setNewTask({ title: "", label: "" });
    }
  };

  const handleAddNewTask = () => {
    addTask(newTask);
    setNewTask({ title: "", label: "" });
  };

  return (
    <Fragment>
      {isMobile ? (
        <StyledMobileTaskContainer>
          <StyledNewTask
            list="backlog"
            type="text"
            value={newTask.title}
            placeholder={t("add_new_task") as string}
            onChange={handleNewTaskChange}
            isMobile={isMobile}
          />
          <datalist id="backlog">
            {backlogTasks.map((task) => (
              <option key={task.id} value={task.title} />
            ))}
          </datalist>
          <Button handleClick={handleAddNewTask} text={t("save")} />
        </StyledMobileTaskContainer>
      ) : (
        <Fragment>
          <StyledNewTask
            list="backlog"
            type="text"
            value={newTask.title}
            autoFocus
            placeholder={t("add_new_task") as string}
            onKeyPress={handleSaveNewTask}
            onChange={handleNewTaskChange}
            isMobile={isMobile}
          />
          <datalist id="backlog">
            {backlogTasks.map((task) => (
              <option key={task.id} value={task.title} />
            ))}
          </datalist>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NewTask;
