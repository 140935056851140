import { Fragment } from "react";

import Authentication from "pages/Authentication";
import DaysDashboard from "pages/Dashboard/Days";
import MonthsDashboard from "pages/Dashboard/Months";
import QuartersDashboard from "pages/Dashboard/Quarters";
import WeeksDashboard from "pages/Dashboard/Weeks";
import YearsDashboard from "pages/Dashboard/Years";
import FourOFour from "pages/FourOFour";
import Home from "pages/Home";
import Privacy from "pages/Privacy";
import Profile from "pages/Profile";
import MonthTrackersStats from "pages/TrackersStats/Months";
import QuarterTrackersStats from "pages/TrackersStats/Quarters";
import WeekTrackersStats from "pages/TrackersStats/Weeks";
import YearTrackersStats from "pages/TrackersStats/Years";
import { Route } from "react-router-dom";
import {
  DAYS_DASHBOARD_ROUTE,
  WEEKS_DASHBOARD_ROUTE,
  MONTHS_DASHBOARD_ROUTE,
  QUARTERS_DASHBOARD_ROUTE,
  YEARS_DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  PRIVACY_ROUTE,
  PROFILE_ROUTE,
  WEEK_TRACKERS_STATS,
  MONTH_TRACKERS_STATS,
  QUARTER_TRACKERS_STATS,
  YEAR_TRACKERS_STATS,
} from "routes";
import AuthenticatedRoute from "routes/AuthenticatedRoute";
import UnAuthenticatedRoute from "routes/UnAuthenticatedRoute";

const routesList = () => (
  <Fragment>
    <Route
      path="/"
      element={
        <UnAuthenticatedRoute>
          <Home />
        </UnAuthenticatedRoute>
      }
    />
    <Route
      path={DAYS_DASHBOARD_ROUTE}
      element={
        <AuthenticatedRoute>
          <DaysDashboard />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={WEEKS_DASHBOARD_ROUTE}
      element={
        <AuthenticatedRoute>
          <WeeksDashboard />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={MONTHS_DASHBOARD_ROUTE}
      element={
        <AuthenticatedRoute>
          <MonthsDashboard />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={QUARTERS_DASHBOARD_ROUTE}
      element={
        <AuthenticatedRoute>
          <QuartersDashboard />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={YEARS_DASHBOARD_ROUTE}
      element={
        <AuthenticatedRoute>
          <YearsDashboard />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={WEEK_TRACKERS_STATS}
      element={
        <AuthenticatedRoute>
          <WeekTrackersStats />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={MONTH_TRACKERS_STATS}
      element={
        <AuthenticatedRoute>
          <MonthTrackersStats />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={QUARTER_TRACKERS_STATS}
      element={
        <AuthenticatedRoute>
          <QuarterTrackersStats />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={YEAR_TRACKERS_STATS}
      element={
        <AuthenticatedRoute>
          <YearTrackersStats />
        </AuthenticatedRoute>
      }
    />
    <Route
      path={LOGIN_ROUTE}
      element={
        <UnAuthenticatedRoute>
          <Authentication />
        </UnAuthenticatedRoute>
      }
    />
    <Route
      path={PROFILE_ROUTE}
      element={
        <AuthenticatedRoute>
          <Profile />
        </AuthenticatedRoute>
      }
    />
    <Route path={PRIVACY_ROUTE} element={<Privacy />} />
    <Route path="*" element={<FourOFour />} />
  </Fragment>
);

export default routesList;
