import { useState, createContext, useEffect } from "react";

import {
  DateRangeStateInterface,
  DayInterface,
  getCurrentWeekDays,
  getSixWeeks,
  periodRange,
  QuarterInterface,
  WeekInterface,
  getQuarters,
  getMonths,
  getActivePeriod,
  MonthInterface,
  getPluralPeriod,
  PluralPeriods,
  getYears,
  YearInterface,
  storingFormatToDate,
  getInitialPeriodId,
} from "helpers/date";

interface PeriodContextInterface {
  children: React.ReactNode;
}

interface TimeContextInterface {
  dateRanges: {
    periodRange: DateRangeStateInterface[];
    days: DayInterface[];
    weeks: WeekInterface[];
    months: MonthInterface[];
    quarters: QuarterInterface[];
    years: YearInterface[];
  };
  onSelectDateRange: (dateType: DateRangesEnum, tabName: string) => void;
  currentPeriod: {
    day: string;
    week: string;
    month: string;
    quarter: string;
    year: string;
  };
  currentPeriodId: PluralPeriods;
  onChangeCurrentDays: (additionalWeeks: number) => void;
  onChangeCurrentWeeks: (additionalWeeks: number) => void;
  onChangeCurrentMonths: (additionalMonths: number) => void;
  onChangeCurrentQuarters: (additionalQuarters: number) => void;
  switchToWeekPeriod: () => void;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const TimeContext = createContext<TimeContextInterface>();

export type DateRangesStateInterface = {
  periodRange: DateRangeStateInterface[];
  days: DayInterface[];
  weeks: WeekInterface[];
  months: MonthInterface[];
  quarters: QuarterInterface[];
  years: YearInterface[];
};

export enum DateRangesEnum {
  periodRange = "periodRange",
  days = "days",
  weeks = "weeks",
  months = "months",
  quarters = "quarters",
  years = "years",
}

export const TimeProvider = ({ children }: PeriodContextInterface) => {
  const days = getCurrentWeekDays();
  const sixWeeks = getSixWeeks();
  const months = getMonths();
  const quarters = getQuarters();
  const years = getYears();

  const [dateRanges, setDateRanges] = useState<DateRangesStateInterface>({
    periodRange: periodRange(window.location.pathname),
    days: days,
    weeks: sixWeeks,
    months: months,
    quarters: quarters,
    years: years,
  });

  const [currentPeriod, setCurrentPeriod] = useState({
    day: getActivePeriod(days),
    week: getActivePeriod(sixWeeks),
    month: getActivePeriod(months),
    quarter: getActivePeriod(quarters),
    year: getActivePeriod(months),
  });

  const [currentPeriodId, setCurrentPeriodId] = useState<PluralPeriods>(
    getInitialPeriodId(window.location.pathname),
  );

  const [firstRender, setFirstRender] = useState<boolean>(false);

  const onSelectDateRange = (
    dateType: DateRangesEnum,
    tabName: string,
  ): any => {
    setDateRanges({
      ...dateRanges,
      [dateType]: dateRanges[dateType].map((item) => ({
        ...item,
        isActive: item.name === tabName,
      })),
    });
    if (dateType === DateRangesEnum.periodRange) {
      setTimeout(() => {
        setDateRanges({
          ...dateRanges,
          [dateType]: dateRanges[dateType].map((item) => ({
            ...item,
            isActive: item.name === tabName,
            isActiveWithDelay: item.name === tabName,
          })),
        });
      }, 400);
    }
  };

  const switchToWeekPeriod = () => {
    onSelectDateRange(DateRangesEnum.periodRange, "Week");
  };

  const onChangeCurrentDays = (additionalWeeks: number) => {
    const startingDay = storingFormatToDate(dateRanges.days[0].id);
    startingDay.setDate(startingDay.getDate() + additionalWeeks * 7);
    const nextDays = getCurrentWeekDays(startingDay, startingDay);
    setDateRanges({
      ...dateRanges,
      days: nextDays,
    });
  };

  const onChangeCurrentWeeks = (additionalWeeks: number) => {
    const startingDay = dateRanges.weeks[2].firstDay;
    startingDay.setDate(startingDay.getDate() + additionalWeeks * 10);

    const nextWeeks = getSixWeeks(startingDay);
    setDateRanges({
      ...dateRanges,
      weeks: nextWeeks,
    });
  };

  const onChangeCurrentMonths = (additionalMonths: number) => {
    const startingDay = dateRanges.months[0].referenceDay;
    startingDay.setDate(startingDay.getDate() + additionalMonths * 30);

    const nextMonths = getMonths(startingDay);
    setDateRanges({
      ...dateRanges,
      months: nextMonths,
    });
  };

  const onChangeCurrentQuarters = (additionalQuarters: number) => {
    const startingYear =
      dateRanges.quarters[0].referenceYear + additionalQuarters * 1;

    const nextQuarters = getQuarters(startingYear);
    setDateRanges({
      ...dateRanges,
      quarters: nextQuarters,
    });
  };

  useEffect(() => {
    const currentDay = dateRanges.days.find((item) => item.isActive)?.id;
    if (currentDay) {
      setCurrentPeriod({ ...currentPeriod, day: currentDay });
    }

    if (firstRender) {
      setCurrentPeriodId("days");
    }
    // eslint-disable-next-line
  }, [dateRanges.days]);

  useEffect(() => {
    const currentWeek = dateRanges.weeks.find((item) => item.isActive)?.id;
    if (currentWeek) {
      setCurrentPeriod({ ...currentPeriod, week: currentWeek });
    }
    // if (firstRender) {
    //   setCurrentPeriodId("weeks");
    // }
    // eslint-disable-next-line
  }, [dateRanges.weeks]);

  useEffect(() => {
    const currentMonth = dateRanges.months.find((item) => item.isActive)?.id;

    if (currentMonth) {
      setCurrentPeriod({ ...currentPeriod, month: currentMonth });
    }
    // if (firstRender) {
    //   setCurrentPeriodId("months");
    // }
    // eslint-disable-next-line
  }, [dateRanges.months]);

  useEffect(() => {
    const currentQuarter = dateRanges.quarters.find(
      (item) => item.isActive,
    )?.id;
    if (currentQuarter) {
      setCurrentPeriod({ ...currentPeriod, quarter: currentQuarter });
    }
    // if (firstRender) {
    //   setCurrentPeriodId("quarters");
    // }
    // eslint-disable-next-line
  }, [dateRanges.quarters]);

  useEffect(() => {
    const currentYear = dateRanges.years.find((item) => item.isActive)?.id;
    if (currentYear) {
      setCurrentPeriod({ ...currentPeriod, year: currentYear });
    }
    // if (firstRender) {
    //   setCurrentPeriodId("years");
    // }
    // eslint-disable-next-line
  }, [dateRanges.years]);

  useEffect(() => {
    const currentRangeid = dateRanges.periodRange.find(
      (range) => range.isActive,
    )?.id;

    if (firstRender && currentRangeid) {
      const currentTasks = dateRanges[getPluralPeriod(currentRangeid)].find(
        (item) => item.isActive,
      )?.id;

      setCurrentPeriod({ ...currentPeriod, [currentRangeid]: currentTasks });
      setCurrentPeriodId(getPluralPeriod(currentRangeid));
    } else if (!firstRender) {
      setFirstRender(true);
    }
    // eslint-disable-next-line
  }, [dateRanges.periodRange]);

  return (
    <TimeContext.Provider
      value={{
        dateRanges,
        onSelectDateRange,
        currentPeriod,
        currentPeriodId,
        onChangeCurrentDays,
        onChangeCurrentWeeks,
        onChangeCurrentMonths,
        onChangeCurrentQuarters,
        switchToWeekPeriod,
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

export default TimeProvider;
