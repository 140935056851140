import { deleteDoc, doc } from "firebase/firestore";

import { db, auth } from "./init";

export const deleteUser = async (uid: string) => {
  const user = auth.currentUser;
  await deleteDoc(doc(db, "trackers", uid));
  await deleteDoc(doc(db, "tasks", uid));
  await deleteDoc(doc(db, "calendars", "googleCalendar", uid, "calendars"));
  await user?.delete();
};
