import styled from "styled-components";

export const StyledDaysOfMonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDayNumber = styled.div<{
  isActive?: boolean;
  isDay?: boolean;
  isLeftColumn?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  color: ${(props) =>
    props.isActive ? "blue" : props.theme.main.colors.primary};
  border-top: ${(props) =>
    props.isFirst && `2px solid ${props.theme.main.colors.primary}`};
  border-right: ${(props) =>
    props.isLast && `1px solid ${props.theme.main.colors.primary}`};
  padding-top: ${(props) => props.isFirst && "2px"};
  border-bottom: 1px solid ${({ theme }) => theme.main.colors.primary};
  border-left: 1px solid ${({ theme }) => theme.main.colors.primary};
  height: 28px;
  width: ${(props) => (props.isLeftColumn ? "40px" : "28px")};
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  padding-left: ${(props) => props.isLeftColumn && "5px"};
  cursor: ${(props) => (props.isDay ? "" : "pointer")};
`;
