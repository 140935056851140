import { useContext, Fragment } from "react";

import CalendarEvent from "components/molecules/CalendarEvent";
import { CalendarContext } from "contexts/CalendarContext";
import { useIsMobile } from "customHooks";
import { useTranslation } from "react-i18next";

import {
  StyledDayTitle,
  StyledDayContainer,
  StyledNoEvents,
  StyledDaysContainer,
} from "./styles";

const CalendarWeekEvents = () => {
  const { t } = useTranslation();

  const { weeklyEvents } = useContext(CalendarContext);

  const isMobile = useIsMobile();

  return (
    <StyledDaysContainer isMobile={isMobile}>
      {weeklyEvents.map((day: any) => (
        <StyledDayContainer key={day.id}>
          <StyledDayTitle>{day.name}</StyledDayTitle>
          <Fragment>
            {day.events.length ? (
              <Fragment>
                {day.events.map((event: any) => {
                  return (
                    <CalendarEvent key={event.id} event={event} noBorder />
                  );
                })}
              </Fragment>
            ) : (
              <StyledNoEvents isPast={day.day < new Date()}>
                {t("no_events_for_this_day")}
              </StyledNoEvents>
            )}
          </Fragment>
        </StyledDayContainer>
      ))}
    </StyledDaysContainer>
  );
};

export default CalendarWeekEvents;
