import { Fragment, useContext } from "react";

import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import DateRangeSelectorYears from "components/molecules/DateRangeSelectorYears";
import TasksContainer from "components/organism/TasksContainer";
import DashboardTemplate from "components/template/Dashboard";
import { LayoutContext } from "contexts/LayoutContext";
import { TimeContext } from "contexts/TimeContext";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "../../../customHooks/index";

const DashboardYears = () => {
  const { t } = useTranslation();

  const { dateRanges } = useContext(TimeContext);
  const { periodRange } = dateRanges;

  const { mobileSectionToDisplay } = useContext(LayoutContext);

  const isMobile = useIsMobile();

  return (
    <Fragment>
      <DateRangeSelectorPeriodType />
      <DateRangeSelectorYears />
      <DashboardTemplate isQuarterOrYear>
        <div>
          {mobileSectionToDisplay === "tasks" || !isMobile ? (
            <TasksContainer
              taskType="years"
              visible={periodRange[4].isActiveWithDelay}
            />
          ) : (
            <div style={{ padding: "20px" }}>{t("no_data_mobile")}</div>
          )}
        </div>
      </DashboardTemplate>
    </Fragment>
  );
};

export default DashboardYears;
