import styled from "styled-components";

export const StyledSectionTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.main.colors.primary};
  font-size: 18px;
  letter-spacing: 1px;
  padding-bottom: 16px;
  margin-bottom: 28px;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.main.colors.primary};
`;

export const StyledSettingsIconContainer = styled.div<{ withTabs?: boolean }>`
  cursor: pointer;
  display: flex;
`;

export const StyledTrackersButtonsContainer = styled.div`
  padding-right: 30px;
  display: flex;
  gap: 10px;
`;
