import { Fragment } from "react";

import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import DateRangeSelectorWeeks from "components/molecules/DateRangeSelectorWeeks";
import WeeksTrackers from "components/organism/Trackers/WeeksTrackers";
import {
  BARCHART_MODE,
  TrackerModeType,
} from "components/template/CalendarAndTrackers";
import DashboardTemplate from "components/template/Dashboard";

const DashboardWeek = () => {
  const currentTrackerMode: TrackerModeType = BARCHART_MODE;

  return (
    <Fragment>
      <DateRangeSelectorPeriodType />
      <DateRangeSelectorWeeks />
      <DashboardTemplate>
        <WeeksTrackers currentTrackerMode={currentTrackerMode} />
      </DashboardTemplate>
    </Fragment>
  );
};

export default DashboardWeek;
