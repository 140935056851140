import styled from "styled-components";

export const StyledHomeContainer = styled.div<{
  isQuarterOrYear?: boolean;
  isMobile: boolean;
}>`
  background-color: ${(props) => props.theme.main.colors.secondary};
  display: grid;
  grid-template-columns: 20fr 15fr 15fr;
  grid-template-columns: ${(props) =>
    props.isQuarterOrYear && !props.isMobile
      ? "40fr 30fr"
      : props.isMobile
      ? "1fr"
      : "20fr 15fr 15fr"};
  height: 100%;
  margin: 0;
  gap: 40px;
  padding: 40px 30px;
`;

export const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
