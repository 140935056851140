import styled from "styled-components";

export const StyledStatSwitcherContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.main.colors.primaryXLight};
  border-radius: 8px;
  width: 190px;
  height: fit-content;
`;

export const StyledTab = styled.div<{ isActive: boolean }>`
  color: ${(props) => props.theme.main.colors.primary};
  font-size: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  background-color: ${(props) =>
    props.isActive && props.theme.main.colors.bgTasksDone};
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.main.colors.primaryXLight};
  align-items: center;
  gap: 12px;
  &:hover {
    background-color: ${(props) => props.theme.main.colors.bgTasksTodo};
  }
`;
