import { useContext, useMemo } from "react";

import { TimeContext } from "contexts/TimeContext";
import { TrackersContext } from "contexts/TrackersContext";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Legend,
  Line,
} from "recharts";

const QuartersGraph = () => {
  const { quarterTrackers } = useContext(TrackersContext);

  const { currentPeriod } = useContext(TimeContext);

  const colors = scaleOrdinal(schemeCategory10).range();

  const formattedData = useMemo(() => {
    const formattedTrackersData: any[] | undefined = [];
    quarterTrackers.forEach((monthTrackers, i) => {
      const monthData: any = {};
      monthTrackers.forEach((tracker) => {
        monthData[tracker.name] = tracker.completedDays.length || 0;
      });
      let formatDate;
      const lastNumber = parseInt(currentPeriod.month.slice(-1));
      if (i === 0) {
        formatDate =
          currentPeriod.month.slice(0, -1) + (lastNumber - 2) + "-01";
      } else if (i === 1) {
        formatDate =
          currentPeriod.month.slice(0, -1) + (lastNumber - 1) + "-01";
      } else {
        formatDate = currentPeriod.month.slice(0, -1) + lastNumber + "-01";
      }

      const date = new Date(formatDate); // add a day to the string to create a valid date object
      const monthString = date.toLocaleString("default", { month: "long" });
      monthData.name = monthString;
      formattedTrackersData.push(monthData);
    });

    return formattedTrackersData;
    // eslint-disable-next-line
  }, [quarterTrackers, currentPeriod]);

  const trackerNames = useMemo(() => {
    const names: string[] = [];
    quarterTrackers.forEach((monthTrackers) => {
      monthTrackers.forEach((tracker) => {
        if (names.includes(tracker.name)) return;
        names.push(tracker.name);
      });
    });

    return names;
  }, [quarterTrackers]);

  return (
    <LineChart
      width={1050}
      height={600}
      data={formattedData}
      barSize={60}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {trackerNames.length &&
        trackerNames.map((dataKey: any, i: any) => (
          <Line
            key={i}
            strokeWidth={3}
            type="monotone"
            dataKey={dataKey}
            stroke={colors[i % colors.length]}
            activeDot={{ r: 8 }}
          />
        ))}
    </LineChart>
  );
};

export default QuartersGraph;
