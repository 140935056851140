import { GoogleCalendarEvent } from "types/calendar";

type formattedEventsOfTheWeek = {
  name: string;
  id: number;
  events: GoogleCalendarEvent[];
  day?: Date;
}[];

interface DateTimeFormatOptions {
  weekday: "long";
  year: "numeric";
  month: "long";
  day: "numeric";
}

export const getFormattedEventsOfTheWeek = (
  events: GoogleCalendarEvent[],
  startingDay: string,
): formattedEventsOfTheWeek => {
  const dayNameOptions: DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const monday = new Date(startingDay);
  const mondayFormatted = monday.toLocaleDateString("en-US", dayNameOptions);
  const tuesday = new Date(startingDay);
  tuesday.setDate(monday.getDate() + 1);
  const tuesdayFormatted = tuesday.toLocaleDateString("en-US", dayNameOptions);
  const wednesday = new Date(startingDay);
  wednesday.setDate(monday.getDate() + 2);
  const wednesdayFormatted = wednesday.toLocaleDateString(
    "en-US",
    dayNameOptions,
  );
  const thursday = new Date(startingDay);
  thursday.setDate(monday.getDate() + 3);
  const thursdayFormatted = thursday.toLocaleDateString(
    "en-US",
    dayNameOptions,
  );
  const friday = new Date(startingDay);
  friday.setDate(monday.getDate() + 4);
  const fridayFormatted = friday.toLocaleDateString("en-US", dayNameOptions);
  const saturday = new Date(startingDay);
  saturday.setDate(monday.getDate() + 5);
  const saturdayFormatted = saturday.toLocaleDateString(
    "en-US",
    dayNameOptions,
  );
  const sunday = new Date(startingDay);
  sunday.setDate(monday.getDate() + 6);
  const sundayFormatted = sunday.toLocaleDateString("en-US", dayNameOptions);

  const formattedEventsOfTheWeek: formattedEventsOfTheWeek = [
    {
      name: mondayFormatted,
      id: 1,
      events: [],
    },
    {
      name: tuesdayFormatted,
      id: 2,
      events: [],
    },
    { name: wednesdayFormatted, id: 3, events: [] },
    { name: thursdayFormatted, id: 4, events: [] },
    { name: fridayFormatted, id: 5, events: [] },
    { name: saturdayFormatted, id: 6, events: [] },
    { name: sundayFormatted, id: 0, events: [] },
  ];

  events.forEach((event: GoogleCalendarEvent) => {
    const startTime = event.start?.dateTime;
    if (!startTime) return;

    const endTime = event.end?.dateTime;

    if (!startTime || !endTime) return;
    const dayNumber = new Date(startTime).getDay();

    // eslint-disable-next-line prefer-const
    let appropriateDay = formattedEventsOfTheWeek.find(
      (day) => day.id === dayNumber,
    );

    if (!appropriateDay) return;

    appropriateDay.events.push(event);
    appropriateDay.day = new Date(startTime);
  });

  return formattedEventsOfTheWeek;
};

export const getFormattedEventsOfTheMonth = (
  events: GoogleCalendarEvent[],
  numberOfDaysInMonth: number,
  firstDay: Date,
): unknown => {
  const formattedEventsOfTheMonth: any = [];
  for (let i = 1; i <= numberOfDaysInMonth; i++) {
    const eventsOfTheDay = events.filter((event) => {
      const startTime = event?.start?.dateTime;
      if (!startTime) return false;
      const eventDay = new Date(startTime).getDate();
      return eventDay === i;
    });

    formattedEventsOfTheMonth.push({
      name: new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        i,
      ).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
      events: [...eventsOfTheDay],
      id: i,
    });
  }

  return formattedEventsOfTheMonth;
};
