import { useContext, useMemo } from "react";

import { TrackersContext } from "contexts/TrackersContext";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const MonthlyBarChart = () => {
  const { weekTrackers } = useContext(TrackersContext);

  const colors = scaleOrdinal(schemeCategory10).range();

  const formattedTrackersData: any[] = useMemo(() => {
    const data: any[] = [];

    weekTrackers.forEach((tracker, i) => {
      const trackerData = {
        name: tracker.name,
        Amount: tracker.completedDays.length,
        fill: colors[i % colors.length],
      };
      data.push(trackerData);
    });

    return data;
  }, [weekTrackers]);

  if (!formattedTrackersData.length)
    return <h3>You don't have trackers yet</h3>;

  return (
    <BarChart
      width={1250}
      height={600}
      data={formattedTrackersData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid />
      <XAxis dataKey="name" />

      <YAxis allowDecimals={false} />
      <Tooltip />
      <Bar dataKey="Amount" fill="#8884d8" label />
    </BarChart>
  );
};

export default MonthlyBarChart;
