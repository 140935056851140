import { useState, useContext } from "react";

import LoaderList from "components/atoms/LoaderList";
import TitleSection from "components/atoms/TitleSection";
import { CalendarContext } from "contexts/CalendarContext";
import { useIsMobile } from "customHooks";
import { useTranslation } from "react-i18next";

import CalendarDayEvents from "./CalendarDayEvents";
import EditCalendarsSelectedDialog from "./EditCalendarsSelectedDialog/index";
import { StyledCalendarSection } from "./styles";
import SynchronizeCalendarsModal from "../SynchronizeCalendarsModal";

const ViewDay = () => {
  const { t } = useTranslation();

  const { calendarsLoading, hasCalendar, getCalendars } =
    useContext(CalendarContext);

  const isMobile = useIsMobile();

  const [openEditCalendarsSelected, setOpenEditCalendarsSelected] =
    useState(false);

  const toggleOpenEditCalendarsSelected = () =>
    setOpenEditCalendarsSelected(!openEditCalendarsSelected);

  const openEditCalendarsSelectedDialog = async () => {
    await getCalendars();
    toggleOpenEditCalendarsSelected();
  };

  return (
    <StyledCalendarSection isMobile={isMobile}>
      <TitleSection settingClick={openEditCalendarsSelectedDialog}>
        {t("calendar_appointments")}
      </TitleSection>
      {calendarsLoading || hasCalendar === null ? (
        <LoaderList />
      ) : hasCalendar ? (
        <CalendarDayEvents />
      ) : (
        <SynchronizeCalendarsModal />
      )}
      {openEditCalendarsSelected && (
        <EditCalendarsSelectedDialog
          handleClose={toggleOpenEditCalendarsSelected}
        />
      )}
    </StyledCalendarSection>
  );
};

export default ViewDay;
