import React from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "App";
import AlertProvider from "contexts/AlertContext";
import CalendarProvider from "contexts/CalendarContext";
import LayoutProvider from "contexts/LayoutContext";
import TasksProvider from "contexts/TasksContext";
import TimeProvider from "contexts/TimeContext";
import TrakersProvider from "contexts/TrackersContext";
import UserProvider from "contexts/UserContext";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import Theme from "theme/Theme";

import i18n from "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root") as Element).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Theme>
        <LayoutProvider>
          <AlertProvider>
            <UserProvider>
              <GoogleOAuthProvider clientId="868627582523-f1874ps9g792fffjdikb5gfuvuf711jc.apps.googleusercontent.com">
                <TimeProvider>
                  <CalendarProvider>
                    <TasksProvider>
                      <TrakersProvider>
                        <App />
                      </TrakersProvider>
                    </TasksProvider>
                  </CalendarProvider>
                </TimeProvider>
              </GoogleOAuthProvider>
            </UserProvider>
          </AlertProvider>
        </LayoutProvider>
      </Theme>
    </I18nextProvider>
  </React.StrictMode>,
);

serviceWorkerRegistration.register();
