import { createContext, useContext, useEffect, useState } from "react";

import axios from "axios";
import { UserContext } from "contexts/UserContext";
import {
  getCalendars as _getCalendars,
  getSelectedCalendars as _getSelectedCalendars,
  initGoogleCalendar as _initGoogleCalendar,
  storeCalendars as _storeCalendars,
} from "fire/calendar";
import {
  getFormattedEventsOfTheWeek,
  getFormattedEventsOfTheMonth,
} from "helpers/calendar";
import { storingFormatToDate } from "helpers/date";
import { GoogleCalendarEvent } from "types/calendar";

import { TimeContext } from "./TimeContext";

interface UserContextInterface {
  children: React.ReactNode;
}

interface CalendarContextInterface {
  calendarsLoading: boolean;
  hasCalendar: boolean | null;
  initGoogleCalendar: (code: string) => Promise<boolean>;
  calendars: any[];
  synchronizeCalendar: (calendars: any[]) => void;
  getSelectedCalendars: () => void;
  getCalendars: () => Promise<void>;
  weeklyEvents: GoogleCalendarEvent[];
  dailyEvents: GoogleCalendarEvent[];
  monthlyEvents: GoogleCalendarEvent[];
  selectedCalendars: SelectedCalendarsInterface;
}

type SelectedCalendarsInterface = { id: string; summary: string }[] | null;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CalendarContext = createContext<CalendarContextInterface>(null);

const CalendarProvider = ({ children }: UserContextInterface) => {
  const { currentUser } = useContext(UserContext);

  const [hasCalendar, setHasCalendar] = useState<boolean | null>(null);
  const [calendarsLoading, setCalendarsLoading] = useState(true);
  const [googleCalendarAccessToken, setGoogleCalendarAccessToken] = useState<
    string | null
  >(null);
  const [calendars, setCalendars] = useState<any>([]);

  const [dailyEvents, setCalendarEvents] = useState<any>([]);
  const [weeklyEvents, setWeeklyEvents] = useState<any>([]);
  const [monthlyEvents, setMonthlyEvents] = useState<any>([]);

  const [selectedCalendars, setSelectedCalendars] =
    useState<SelectedCalendarsInterface>(null);

  const { currentPeriod, dateRanges, currentPeriodId } =
    useContext(TimeContext);

  const getSelectedCalendars = async () => {
    setCalendarsLoading(true);
    try {
      const response: any = await _getSelectedCalendars();
      setGoogleCalendarAccessToken(response.data.accessToken);
      setSelectedCalendars(response.data.calendars.basicInformations);
    } catch (error) {
      setCalendarsLoading(false);
      setHasCalendar(false);
      console.log(error);
    }
  };

  const initGoogleCalendar = async (code: string): Promise<boolean> => {
    try {
      const initData: any = await _initGoogleCalendar(code);

      setGoogleCalendarAccessToken(initData.data);
      const calendarsData = await _getCalendars(initData.data);

      const calendarItems = calendarsData.data.items.filter(
        (cal: any) => cal.summary !== "Numéros de semaine",
      );

      setCalendars(calendarItems);
      return true;
    } catch (error) {
      return false;
    }
  };

  const synchronizeCalendar = async (calendars: any[]) => {
    setCalendarsLoading(true);
    try {
      const formatedCalendars = calendars.map((calendar: any) => ({
        id: calendar.id,
        summary: calendar.summary,
      }));
      await _storeCalendars(currentUser.uid, formatedCalendars);
      setSelectedCalendars(formatedCalendars);
      setHasCalendar(true);
    } catch (error) {
      setHasCalendar(false);
      setCalendarsLoading(false);
      console.log(error);
    }
  };

  const getCalendars = async () => {
    setCalendarsLoading(true);
    try {
      if (!googleCalendarAccessToken) return;
      const calendarsData = await _getCalendars(googleCalendarAccessToken);
      const calendarItems = calendarsData.data.items.filter(
        (cal: any) => cal.summary !== "Numéros de semaine",
      );
      setCalendars(calendarItems);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarsLoading(false);
    }
  };

  const getDailyData = async () => {
    setCalendarsLoading(true);

    const date = storingFormatToDate(currentPeriod.day);

    const timeMin = date.toISOString().split("T")[0] + "T00:00:00.000Z";
    const timeMax = date.toISOString().split("T")[0] + "T23:59:59.000Z";

    if (!selectedCalendars || selectedCalendars.length === 0) {
      console.log("no calendar selected");
      setHasCalendar(false);
      setCalendarsLoading(false);
      return;
    }

    const calendarIds = selectedCalendars.map((calendar: any) => calendar.id);
    setHasCalendar(true);
    const events = [];

    try {
      if (!calendarIds.length) return;
      for (const calendarId of calendarIds) {
        try {
          const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${timeMin}&timeMax=${timeMax}&singleEvents=true`;
          const eventsResponse = await axios.get(apiUrl, {
            headers: { Authorization: `Bearer ${googleCalendarAccessToken}` },
          });

          let thatCalendarEvents = eventsResponse.data.items;
          // filter events that have status cancelled
          thatCalendarEvents = thatCalendarEvents.filter(
            (event: any) => event.status !== "cancelled",
          );
          events.push(...thatCalendarEvents);
        } catch (error) {
          console.error(
            `Error getting events for calendar with ID ${calendarId}:`,
            error,
          );
        }
      }
      events.sort(function (a: any, b: any) {
        const startA = new Date(a.start.dateTime);
        const startB = new Date(b.start.dateTime);
        return startA.getTime() - startB.getTime();
      });

      setCalendarEvents(events);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarsLoading(false);
    }
  };

  const getWeeklyData = async () => {
    setCalendarsLoading(true);

    const currentWeek: any = dateRanges.weeks.find(
      (week: any) => week.isActive,
    );

    const timeMin =
      currentWeek.start.toISOString().split("T")[0] + "T00:00:00.000Z";
    const timeMax =
      currentWeek.end.toISOString().split("T")[0] + "T23:59:59.000Z";

    if (!selectedCalendars || selectedCalendars.length === 0) {
      console.log("no calendar selected");
      setHasCalendar(false);
      setCalendarsLoading(false);
      return;
    }

    const calendarIds = selectedCalendars.map((calendar: any) => calendar.id);
    setHasCalendar(true);
    const events = [];

    try {
      if (!calendarIds.length) return;
      for (const calendarId of calendarIds) {
        try {
          const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${timeMin}&timeMax=${timeMax}&singleEvents=true&orderBy=startTime`;
          const eventsResponse = await axios.get(apiUrl, {
            headers: { Authorization: `Bearer ${googleCalendarAccessToken}` },
          });

          let thatCalendarEvents = eventsResponse.data.items;
          // filter events that have status cancelled
          thatCalendarEvents = thatCalendarEvents.filter(
            (event: any) => event.status !== "cancelled",
          );

          events.push(...thatCalendarEvents);
        } catch (error) {
          console.error(
            `Error getting events for calendar with ID ${calendarId}:`,
            error,
          );
        }
      }

      const formattedEventsOfTheWeek = getFormattedEventsOfTheWeek(
        events,
        timeMin,
      );

      events.sort(function (a: any, b: any) {
        const startA = new Date(a.start.dateTime);
        const startB = new Date(b.start.dateTime);
        return startA.getTime() - startB.getTime();
      });

      setWeeklyEvents(formattedEventsOfTheWeek);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarsLoading(false);
    }
  };

  const getMonthlyData = async () => {
    setCalendarsLoading(true);

    const currentMonth: any = dateRanges.months.find((month) => month.isActive);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // eslint-disable-next-line prefer-const
    let [year, month] = currentMonth.id.split("-");

    month = parseInt(month) - 1;

    const options = { timeZone: userTimeZone };

    const timeMin = new Date(year, month, 1).toLocaleDateString(
      "en-US",
      options,
    );
    const timeMax = new Date(year, month + 1, 0).toLocaleDateString(
      "en-US",
      options,
    );

    const adjustedTimeMin = new Date(timeMin).toISOString();
    const adjustedTimeMax = new Date(timeMax).toISOString();

    const firstDay = new Date(year, month, 1);

    const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();
    console.log(
      "🚀 ~ file: CalendarContext.tsx:259 ~ getMonthlyData ~ numberOfDaysInMonth:",
      numberOfDaysInMonth,
    );

    if (!selectedCalendars || selectedCalendars.length === 0) {
      console.log("no calendar selected");
      setHasCalendar(false);
      setCalendarsLoading(false);
      return;
    }

    const calendarIds = selectedCalendars.map((calendar: any) => calendar.id);
    setHasCalendar(true);
    const events = [];

    try {
      if (!calendarIds.length) return;
      for (const calendarId of calendarIds) {
        try {
          const apiUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${adjustedTimeMin}&timeMax=${adjustedTimeMax}&singleEvents=true&orderBy=startTime`;
          const eventsResponse = await axios.get(apiUrl, {
            headers: { Authorization: `Bearer ${googleCalendarAccessToken}` },
          });

          let thatCalendarEvents = eventsResponse.data.items;

          // filter events that have status cancelled
          thatCalendarEvents = thatCalendarEvents.filter(
            (event: any) => event.status !== "cancelled",
          );

          events.push(...thatCalendarEvents);
        } catch (error) {
          console.error(
            `Error getting events for calendar with ID ${calendarId}:`,
            error,
          );
        }
      }

      const formattedEventsOfTheWeek = getFormattedEventsOfTheMonth(
        events,
        numberOfDaysInMonth,
        firstDay,
      );
      console.log(
        "🚀 ~ file: CalendarContext.tsx:311 ~ getMonthlyData ~ formattedEventsOfTheWeek:",
        formattedEventsOfTheWeek,
      );

      events.sort(function (a: any, b: any) {
        const startA = new Date(a.start.dateTime);
        const startB = new Date(b.start.dateTime);
        return startA.getTime() - startB.getTime();
      });

      setMonthlyEvents(formattedEventsOfTheWeek);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCalendars && currentUser && currentPeriodId === "months") {
      getMonthlyData();
    } else {
      setCalendarsLoading(false);
    }
    // eslint-disable-next-line
  }, [currentPeriod.month, selectedCalendars, currentPeriodId]);

  useEffect(() => {
    if (selectedCalendars && currentUser && currentPeriodId === "weeks") {
      getWeeklyData();
    } else {
      setCalendarsLoading(false);
    }
    // eslint-disable-next-line
  }, [currentPeriod.week, selectedCalendars, currentPeriodId]);

  useEffect(() => {
    if (selectedCalendars && currentUser && currentPeriodId === "days") {
      getDailyData();
    } else {
      setCalendarsLoading(false);
    }
    // eslint-disable-next-line
  }, [currentPeriod.day, selectedCalendars, currentPeriodId]);

  useEffect(() => {
    if (currentUser) {
      getSelectedCalendars();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <CalendarContext.Provider
      value={{
        calendarsLoading,
        hasCalendar,
        initGoogleCalendar,
        calendars,
        synchronizeCalendar,
        getSelectedCalendars,
        selectedCalendars,
        getCalendars,
        dailyEvents,
        weeklyEvents,
        monthlyEvents,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
