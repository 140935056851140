import { TrackInterface } from "contexts/TrackersContext";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";

import { formatFirebaseTasks, uniqueId } from "./helpers";
import { db } from "./init";

export const getTracks = async (
  uid: string,
  month: string,
): Promise<TrackInterface[]> => {
  const trackersCol = collection(db, `trackers/${uid}/${month}`);
  const docs = await getDocs(trackersCol);
  const formatedData = formatFirebaseTasks(docs);

  return formatedData;
};

export const toggleCompleteTrack = async (
  uid: string,
  month: string,
  trackerId: string,
  day: number,
  tracker: TrackInterface,
): Promise<number[]> => {
  const trackerDoc = doc(db, "trackers", uid, month, trackerId);
  const docExists = (await getDoc(trackerDoc)).exists();

  if (!docExists) {
    await setDoc(trackerDoc, {
      name: tracker.name,
      label: tracker.label,
      completedDays: [day],
      trackerId: tracker.id,
    });
    return [day];
  }

  const completedDays = tracker.completedDays;
  if (completedDays.includes(day)) {
    const newCompletedDays = completedDays.filter((item) => item !== day);
    await updateDoc(trackerDoc, {
      completedDays: newCompletedDays,
    });
    return newCompletedDays;
  } else {
    const newCompletedDays = [...completedDays, day];
    await updateDoc(trackerDoc, {
      completedDays: newCompletedDays,
    });
    return newCompletedDays;
  }
};

export const toggleWeekTracker = async (
  uid: string,
  month: string,
  trackerId: string,
  day: number,
  tracker: TrackInterface,
): Promise<number[]> => {
  const trackerDoc = doc(db, "trackers", uid, month, trackerId);
  const docExists = (await getDoc(trackerDoc)).exists();

  if (!docExists) {
    await setDoc(trackerDoc, {
      name: tracker.name,
      label: tracker.label,
      completedDays: [day],
      trackerId: tracker.id,
    });
    return [day];
  }

  const data = (await getDoc(trackerDoc)).data();
  const completedDays: any[] = data?.completedDays;

  if (!completedDays) {
    await updateDoc(trackerDoc, {
      completedDays: [day],
    });
    return [day];
  } else if (completedDays.includes(day)) {
    const newCompletedDays = completedDays.filter((item) => item !== day);
    await updateDoc(trackerDoc, {
      completedDays: newCompletedDays,
    });
    return newCompletedDays;
  } else {
    const newCompletedDays = [...completedDays, day];
    await updateDoc(trackerDoc, {
      completedDays: newCompletedDays,
    });
    return newCompletedDays;
  }
};

export const addTracker = async (
  trackName: string,
  month: string,
  uid: string,
) => {
  const trackersList = collection(db, `settingsTrackers/${uid}/trackerList`);
  const docs = await getDocs(trackersList);
  const formatedData = formatFirebaseTasks(docs);
  const alreadyExistingTracker = formatedData.find(
    (tracker: any) => tracker.name === trackName,
  );

  if (alreadyExistingTracker) {
    await setDoc(
      doc(db, "trackers", uid, month, alreadyExistingTracker.trackerId),
      {
        name: alreadyExistingTracker.name,
        label: alreadyExistingTracker.label,
        completedDays: [],
        trackerId: alreadyExistingTracker.trackerId,
      },
    );
    return {
      name: alreadyExistingTracker.name,
      label: alreadyExistingTracker.label,
      id: alreadyExistingTracker.trackerId,
      completedDays: [],
    };
  } else {
    const trackerId = uniqueId();
    await setDoc(doc(db, "trackers", uid, month, trackerId), {
      name: trackName,
      label: "",
      completedDays: [],
      trackerId: trackerId,
    });
    await setDoc(doc(db, "settingsTrackers", uid, "trackerList", trackerId), {
      name: trackName,
      label: "",
      trackerId: trackerId,
    });
    return {
      name: trackName,
      label: "",
      id: trackerId,
      completedDays: [],
    };
  }
};

export const deleteTracker = async (
  uid: string,
  month: string,
  trackerId: string,
) => {
  const trackerDoc = doc(db, "trackers", uid, month, trackerId);
  await deleteDoc(trackerDoc);
};

export const setSameTrackersThanLastMonth = async (
  uid: string,
  month: string,
  lastMonth: string,
) => {
  const lastMonthTrackersCol = collection(db, `trackers/${uid}/${lastMonth}`);
  const lastMonthDocs = await getDocs(lastMonthTrackersCol);
  const lastMonthFormatedData = formatFirebaseTasks(lastMonthDocs);

  if (lastMonthFormatedData.length === 0) return [];

  lastMonthFormatedData.forEach(async (tracker: any) => {
    await setDoc(doc(db, "trackers", uid, month, tracker.id), {
      name: tracker.name,
      label: tracker.label,
      completedDays: [],
      trackerId: tracker.id,
    });
  });
  return lastMonthFormatedData.map((tracker: any) => {
    return {
      name: tracker.name,
      label: tracker.label,
      id: tracker.id,
      completedDays: [],
    };
  });
};

export const getQuarterTrackers = async (
  uid: string,
  months: string[],
): Promise<any> => {
  const formatedQuarterData: any = [];

  for (const month of months) {
    const trackersCol = collection(db, `trackers/${uid}/${month}`);
    const docs = await getDocs(trackersCol);
    const formatedData = formatFirebaseTasks(docs);
    formatedQuarterData.push(formatedData);
  }

  const trackerNames: any[] = [];

  formatedQuarterData.forEach((month: any) => {
    month.forEach((tracker: any) => {
      if (!trackerNames.includes(tracker.name)) {
        trackerNames.push({
          name: tracker.name,
          label: tracker.label,
          id: tracker.id,
          completedDays: [],
          trackerId: tracker.trackerId,
        });
      }
    });
  });

  formatedQuarterData.forEach((month: any) => {
    trackerNames.forEach((tracker: any) => {
      const trackerInMonth = month.find(
        (trackerInMonth: any) => trackerInMonth.name === tracker.name,
      );
      if (!trackerInMonth) {
        month.push({
          name: tracker.name,
          label: tracker.label,
          id: tracker.id,
          completedDays: [],
          trackerId: tracker.trackerId,
        });
      }
    });
  });

  return formatedQuarterData;
};
