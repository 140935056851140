import styled from "styled-components";

export const StyledTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  background: #f3f5f9;
  padding: 6px 12px;
  border-radius: 8px;
`;

export const StyledDeleteIconContainer = styled.div`
  cursor: pointer;
`;
