import { Fragment, useContext, useState } from "react";

import Button from "components/atoms/Button";
import { UserContext } from "contexts/UserContext";
import { auth } from "fire/init";
import { signOut } from "firebase/auth";
import { useTranslation } from "react-i18next";

import DeleteUserDialog from "./DeleteUserDialog";
import {
  ProfileContainer,
  ProfileSection,
  SectionHeader,
  SectionItem,
  ItemLabel,
  ItemValue,
} from "./styles";

const ProfilePage = () => {
  const { t } = useTranslation();

  const { currentUser, deleteUser } = useContext(UserContext);

  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const toggleDeleteUser = () => setOpenDeleteUser(!openDeleteUser);

  const handleLogOut = () => {
    signOut(auth)
      .then((r) => console.log(r))
      .catch();
  };

  return (
    <Fragment>
      <ProfileContainer>
        <ProfileSection>
          <SectionHeader>{t("personal_information")}</SectionHeader>
          <SectionItem>
            <ItemLabel>{t("name")}</ItemLabel>
            <ItemValue>
              {currentUser.displayName ? currentUser.displayName : "-"}
            </ItemValue>
          </SectionItem>
          <SectionItem withMarginBottom>
            <ItemLabel>Email</ItemLabel>
            <ItemValue>{currentUser.email ? currentUser.email : "-"}</ItemValue>
          </SectionItem>
          <Button handleClick={handleLogOut} text={t("logout")} />
          <br />
          <br />
          <Button handleClick={toggleDeleteUser} text={t("delete_account")} />
        </ProfileSection>
      </ProfileContainer>
      {openDeleteUser && (
        <DeleteUserDialog
          handleClose={toggleDeleteUser}
          title={t("delete_account")}
          deleteUser={deleteUser}
        />
      )}
    </Fragment>
  );
};

export default ProfilePage;
