import { useContext, useState, Fragment, useMemo } from "react";

import Button from "components/atoms/Button";
import DialogSubtitle from "components/atoms/DialogSubtitle";
import Input from "components/atoms/Input";
import LoaderList from "components/atoms/LoaderList";
import Dialog from "components/molecules/Dialog";
import { TimeContext } from "contexts/TimeContext";
import { TrackersContext } from "contexts/TrackersContext";
import { useTranslation } from "react-i18next";

import { StyledAddTracker, StyledAddTrackerInputContainer } from "./styles";
import TrackerSettingsItem from "./TrackerSettingItem";

interface TrackersSettingsProps {
  onClose: () => void;
}

const TrackersSettings = ({ onClose }: TrackersSettingsProps) => {
  const { t } = useTranslation();

  const { currentPeriodId } = useContext(TimeContext);
  const { trackers, trackerSettingLoading, addTracker, weekTrackers } =
    useContext(TrackersContext);

  const currentTrackers = useMemo(() => {
    if (currentPeriodId === "weeks") {
      return weekTrackers;
    } else {
      return trackers;
    }
  }, [currentPeriodId, weekTrackers, trackers]);

  const [newTracker, setNewTracker] = useState<string>("");

  const handleNewTrackerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTracker(e.target.value);
  };

  const handleAddTracker = () => {
    if (!newTracker) return;
    if (currentTrackers.find((tracker) => tracker.name === newTracker)) {
      alert("Tracker already exists");
    }
    addTracker(newTracker);
    setNewTracker("");
  };

  return (
    <Dialog title="Trackers Settings" onClose={onClose}>
      <DialogSubtitle>Add a tracker</DialogSubtitle>
      <StyledAddTracker>
        <StyledAddTrackerInputContainer>
          <Input
            value={newTracker}
            name="newTracker"
            onChange={handleNewTrackerChange}
            placeholder="Tracker name"
            onPressEnter={handleAddTracker}
          />
        </StyledAddTrackerInputContainer>
        <div>
          <Button handleClick={handleAddTracker} text="Add" />
        </div>
      </StyledAddTracker>
      <br />
      {!!currentTrackers.length && (
        <Fragment>
          <DialogSubtitle>{t("trackers_list")}</DialogSubtitle>
          {currentTrackers.map((tracker) => (
            <TrackerSettingsItem
              key={`tracker-${tracker.id}`}
              tracker={tracker}
            />
          ))}
        </Fragment>
      )}
      {trackerSettingLoading && <LoaderList />}
      <br />
    </Dialog>
  );
};

export default TrackersSettings;
