import styled from "styled-components";

export const StyledAppContainer = styled.div<{ isMobile?: boolean }>`
  background-color: ${({ theme }) => theme.main.colors.secondary};
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0;
  min-height: 100vh;

  overflow-y: ${({ isMobile }) => isMobile && "scroll"};
  overflow-x: ${({ isMobile }) => isMobile && "hidden"};
`;

export const StyledTaskContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
