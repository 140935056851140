import { useContext } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import Navigation from "components/molecules/Navigation";
import { UserContext } from "contexts/UserContext";
import { useIsMobile } from "customHooks";
import { BrowserRouter, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import routesList from "routes/RoutesList";

import { StyledAppContainer } from "./styles";

const App = () => {
  const { currentUser } = useContext(UserContext);
  const isMobile = useIsMobile();

  return (
    <GoogleOAuthProvider clientId="868627582523-f1874ps9g792fffjdikb5gfuvuf711jc.apps.googleusercontent.com">
      <BrowserRouter>
        <StyledAppContainer isMobile={isMobile}>
          <Navigation />
          <Routes>{currentUser !== null && routesList()}</Routes>
        </StyledAppContainer>
        <ToastContainer />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
