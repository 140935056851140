import { useContext } from "react";

import DateRangeSelector from "components/molecules/DateRangeSelector";
import { TimeContext } from "contexts/TimeContext";
import { DateRangesEnum } from "contexts/TimeContext";

const Years = () => {
  const { dateRanges, onSelectDateRange } = useContext(TimeContext);
  const { years } = dateRanges;

  return (
    <DateRangeSelector
      dateRanges={years}
      onSelectDateRange={onSelectDateRange}
      dateType={DateRangesEnum.years}
      fadeIt
    />
  );
};

export default Years;
