import { Fragment, useMemo, useState } from "react";

import DateRangeSelectorPeriodType from "components/molecules/DateRangeSelectorPeriodType";
import DateRangeSelectorYears from "components/molecules/DateRangeSelectorYears";
import StatSwitcher from "components/molecules/StatSwitcher";
import YearsTrackers, {
  BARCHART_MODE,
  GRAPH_MODE,
  TrackerModeType,
} from "components/organism/Trackers/YearsTrackers";
import TrackersStatTemplate from "components/template/TrackersStats";

const DashboardYears = () => {
  const [trackerOptions, setTrackersOptions] = useState<any[]>([
    {
      label: "Bar Chart",
      value: BARCHART_MODE,
      isActive: true,
    },
    {
      label: "Graphics",
      value: GRAPH_MODE,
      isActive: false,
    },
  ]);

  const onChangeStatOption = (value: TrackerModeType) => {
    const newTrackerOptions = trackerOptions.map((option) => {
      if (option.value === value) {
        return {
          ...option,
          isActive: true,
        };
      }

      return {
        ...option,
        isActive: false,
      };
    });

    setTrackersOptions(newTrackerOptions);
  };

  const currentTrackerOption = useMemo(() => {
    return (
      trackerOptions.find((option) => option.isActive)?.value || BARCHART_MODE
    );
  }, [trackerOptions]);

  return (
    <Fragment>
      <DateRangeSelectorPeriodType />
      <DateRangeSelectorYears />
      <TrackersStatTemplate isQuarterOrYear>
        <StatSwitcher
          trackerOptions={trackerOptions}
          onChangeStatOption={onChangeStatOption}
        />
        <YearsTrackers currentTrackerOption={currentTrackerOption} />
      </TrackersStatTemplate>
    </Fragment>
  );
};

export default DashboardYears;
