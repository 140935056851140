import styled from "styled-components";

export const StyledMonthTableContainer = styled.div`
  display: flex;
  height: 100%;
  margin-top: -2px;
`;

export const StyledMonthTrackersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
