import { useContext, useMemo } from "react";

import LoaderList from "components/atoms/LoaderList";
import { TrackersContext } from "contexts/TrackersContext";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const MonthlyBarChart = () => {
  const { tracksLoading, yearTrackers } = useContext(TrackersContext);

  const colors = scaleOrdinal(schemeCategory10).range();

  const data = useMemo(() => {
    const formattedTrackersData: any[] | undefined = [];
    yearTrackers.forEach((monthTrackers) => {
      monthTrackers.forEach((tracker, i) => {
        const trackerExists = formattedTrackersData.find(
          (trackerData) => trackerData.name === tracker.name,
        );
        if (trackerExists) {
          trackerExists.Amount += tracker.completedDays.length;
          return;
        }
        const trackerData = {
          name: tracker.name,
          Amount: tracker.completedDays.length,
          fill: colors[i % colors.length],
        };
        formattedTrackersData.push(trackerData);
      });
    });
    return formattedTrackersData;
    // eslint-disable-next-line
  }, [yearTrackers, tracksLoading]);

  if (tracksLoading) return <LoaderList />;

  if (!data.length) return <div>No data to display</div>;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BarChart
        width={1050}
        height={600}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="Amount" fill="#8884d8" label />
      </BarChart>
    </div>
  );
};

export default MonthlyBarChart;
