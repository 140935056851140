import { useContext, useEffect } from "react";

import Button from "components/atoms/Button";
import LoaderList from "components/atoms/LoaderList";
import FadeIn from "components/molecules/FadeIn";
import {
  BARCHART_MODE,
  TABLE_MODE,
  GRAPH_MODE,
} from "components/template/CalendarAndTrackers";
import { TrackersContext } from "contexts/TrackersContext";

import BarChart from "./BarChart";
import DaysAndTrackRows from "./DaysAndTrackRows";
import Graph from "./Graph";
import {
  StyledMonthTableContainer,
  StyledMonthTrackersContainer,
} from "./styles";
import TrackerNames from "./TrackerNames";

interface MonthTrackersProps {
  currentTrackerMode?: string | false;
  toggleOpenTrackersSettings?: () => void;
}

const MonthTrackers = ({
  currentTrackerMode,
  toggleOpenTrackersSettings,
}: MonthTrackersProps) => {
  const { trackers, tracksLoading, getQuarterTrackers, currentTrackerMonth } =
    useContext(TrackersContext);

  useEffect(() => {
    if (currentTrackerMode === GRAPH_MODE) {
      getQuarterTrackers();
    }
    // eslint-disable-next-line
  }, [currentTrackerMode, currentTrackerMonth]);

  if (tracksLoading || tracksLoading === null) return <LoaderList />;

  if (trackers.length === 0 && toggleOpenTrackersSettings)
    return (
      <Button handleClick={toggleOpenTrackersSettings} text="Add trackers" />
    );

  return (
    <StyledMonthTrackersContainer>
      {currentTrackerMode === TABLE_MODE && (
        <FadeIn>
          <TrackerNames />
          <StyledMonthTableContainer>
            <DaysAndTrackRows />
          </StyledMonthTableContainer>
        </FadeIn>
      )}
      {currentTrackerMode === BARCHART_MODE && (
        <FadeIn>
          <BarChart />
        </FadeIn>
      )}
      {currentTrackerMode === GRAPH_MODE && (
        <FadeIn>
          <Graph />
        </FadeIn>
      )}
    </StyledMonthTrackersContainer>
  );
};

export default MonthTrackers;
