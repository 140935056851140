import styled from "styled-components";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: #f6f6f6;
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 0px;
  margin-top: 0px;
`;

const SubSubtitle = styled.h2`
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 80px;
  color: #333;
`;

const Section1 = () => {
  return (
    <Container>
      <Title>Bullet Flow</Title>
      <SubSubtitle>Une nouvelle façon de suivre votre vie</SubSubtitle>
    </Container>
  );
};

export default Section1;
